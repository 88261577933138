import { useCallback } from 'react';
import useActiveHubServices from './useHubServices';
import { compare } from 'compare-versions';

/**
 * @returns a function that can be use to check if a service is installed.
 */
const useIsServiceInstalled = () => {
  const { services, isReady } = useActiveHubServices();

  /**
   * @returns an object with the following properties:
   * - installedWithSameVersion: true if the service is installed with the same version, false otherwise.
   * - installedWithLowerVersion: true if the service is installed with a lower version, false otherwise.
   * - installedWithMissingSecrets: true if the service is installed with a failed status and the error code is 'MISSING_REQUIRED_SECRETS', false otherwise.
   * - isReady: true if the services list has been fully loaded, false otherwise.
   */
  const getIsInstalled = useCallback((serviceName: string, version: string) => {
    const sameVersion = services.available.some(service => service.name === serviceName && service.version === version);
    const lowerVersion = services.available.some(service => service.name === serviceName && compare(service.version, version, '<'));
    const failed = services.failed.find(service => service.name === serviceName && service.version === version);
    const failedWithMissingSecrets = failed && failed.errorCode === 'MISSING_REQUIRED_SECRETS';
    return {
      installedWithSameVersion: sameVersion,
      installedWithLowerVersion: lowerVersion,
      installedWithMissingSecrets: failedWithMissingSecrets,
      /**
       * Whether the services list has been fully loaded.
       */
      isReady,
    };
  }, [services, isReady]);


  return getIsInstalled;
};

export default useIsServiceInstalled;
