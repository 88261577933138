import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'antd';
import styles from './RecipeList.module.css';
import RecipeCard from '@components/RecipeCard/RecipeCard';
import { clearPublishRecipeState } from '@components/layout/marketplace/marketplaceSlice';
import { RecipeResponse } from '@src/types/core_t';
import useQueryString from '@common/hooks/useQueryString';
import 'react-perfect-scrollbar/dist/css/styles.css';
import StyledPagination from '@components/StyledPagination/StyledPagination';
import useMultiIndexSearch, { SearchResult } from '@hooks/useMultiIndexSearch';
import { SearchInputContextType } from '@src/contexts/SearchInputContext';
import useTranslation from '@hooks/useTranslation';

const resultsPerPage = 11;

const GridResults = (props: {
	hits: RecipeResponse[];
	hitRenderer: (hit: RecipeResponse) => React.JSX.Element;
}) => {
	const { hits, hitRenderer } = props;

	return (
    <Row gutter={{ xs: 0, sm: 12, md: 16, lg: 16, xl: 24 }} className={styles.KemuRow}>
      {hits.map((hit) => (
        <Col key={hit.id} xs={24} sm={12} md={12} lg={8} xl={6} xxl={6}>
          {hitRenderer(hit)}
        </Col>
      ))}
    </Row>
  );
};

type Props = {
	onSetSearchContext: (context: SearchInputContextType) => void;
};

const RecipeList = ({ onSetSearchContext }: Props): React.JSX.Element | null => {
	const dispatch = useDispatch();
	const queryString = useQueryString();
	const selectedCard = queryString.get('select');
	const { searchByIndex } = useMultiIndexSearch();
	const [results, setResults] = useState<SearchResult<RecipeResponse> | null>(null);
	const [currentPage, setCurrentPage] = useState(1);
	const t = useTranslation('Marketplace.MyRecipes');

	const handleShowPublisher = useCallback((selectedRecipe: RecipeResponse) => {
		// TODO: Implement
	}, []);

	const handleGlobalSearch = useCallback(async (query: string) => {
		const [results] = await searchByIndex<RecipeResponse>({
			indexName: 'recipe',
			perPage: resultsPerPage,
			queryBy: 'name',
			query,
			page: currentPage,
		});

		setResults(results);
	}, [searchByIndex, currentPage]);

	const handlePageChange = useCallback((page: number) => {
		setCurrentPage(page);
	}, []);

	// Register the search handler when the component mounts
	useEffect(() => {
		onSetSearchContext?.({
			onSearch: handleGlobalSearch,
		});
	}, [onSetSearchContext, handleGlobalSearch]);

	return (
		<div className={styles.RecipeList}>

			{!!results?.documents.length && (
				<StyledPagination
					wrapperClassName={styles.PaginationWrapper}
					current={results.page}
					total={results.total}
					onChange={handlePageChange}
					pageSize={resultsPerPage}
					showSizeChanger={false}
				/>
			)}

			<GridResults
				// onResults={sortRecipes}
				hits={results?.documents || []}
				hitRenderer={
					(hit: RecipeResponse) => (
						<RecipeCard
							recipe={hit}
							selected={selectedCard === hit.id}
							onShowPublisher={handleShowPublisher}
							// onForceReload={handleReload}
						/>
					)
				}
			/>

			{results?.documents && results.documents.length === 0 && (
				<div className={styles.NoResults}>
					<p>{t('NoneFound')}</p>
				</div>
			)}


		</div>
  );
};


export default RecipeList;
