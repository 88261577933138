import { useEffect, useMemo, useState } from 'react';
import { HubSystemInfo } from '@kemu-io/hs-types';
import useKemuHubLink from './useHubLink';
import Logger from '@common/logger.ts';

const log = Logger('useHubSystemInfo');

/**
 * @returns the information about the system the hub is running on.
 */
const useHubSystemInfo = () => {
  const { connector, status } = useKemuHubLink();
  const [systemInfo, setSystemInfo] = useState<HubSystemInfo | null>();
  const isAcknowledged = status === 'acknowledged';

  useEffect(() => {
    if (isAcknowledged) {
      connector.getHubSystemInfo().then(setSystemInfo).catch(err => {
        log.error('Failed to get system info', err);
        setSystemInfo(null);
      });
    }
  }, [connector, isAcknowledged]);

  return systemInfo;
};

export default useHubSystemInfo;
