import { useState, useEffect, useMemo } from 'react';
import posthog from 'posthog-js';

/**
 * Hook to check if a feature is enabled in PostHog.
 * 
 * @param feature - The feature to check.
 * @returns A tuple containing the feature state and a boolean indicating if the feature state has been loaded.
 * 
 * @example
 * const [hasFeature, ready] = useHasPosthogFeature('feature-name');
 * if (ready) {
 *   if (hasFeature) {
 *     // The feature is enabled
 *   }
 */
const useHasPosthogFeature = (feature: string) => {
  const [hasFeature, setHasFeature] = useState<boolean | null>(null);

  useEffect(() => {
    const unsubscribe = posthog.onFeatureFlags(() => {
      const isEnabled = posthog.isFeatureEnabled(feature);
      setHasFeature(!!isEnabled);
    });

    return () => {
      unsubscribe();
    };
  }, [feature]);

  const result = useMemo(() => [hasFeature as boolean, hasFeature !== null], [hasFeature]);
  return result;
};
export default useHasPosthogFeature;
