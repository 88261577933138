import { SerializableServiceInfo } from '@kemu-io/hs-types';
import { useCallback } from 'react';
import useHubServices from '@hooks/useHubServices';

// Official service names
export const DefaultServices = {
  ServiceInstaller: {
    name: 'kemu.io.serviceInstaller',
    actions: {
      installService: 'installService',
      uninstallService: 'uninstallService',
    }
  },
  ProjectCreator: {
    name: 'kemu.io.projectCreator',
    actions: {
      generateProject: 'generateProject',
    }
  }
};

/**
 * Helper function to find the project creator service in the list of active services.
 * @param services a list of active services
 * @returns a service info object if the project creator service is found, otherwise null.
 */
export const findService = (services: SerializableServiceInfo[], targetService: string): SerializableServiceInfo | null => {
  const projectCreator  = services.find((service) => service.name === targetService && service.internal);
  return projectCreator || null;
};

/**
 * Hook to get one of the available default services from the connected hub.
 * @returns a service info object if the service is found, otherwise null.
 */
const useHubDefaultService = () => {
  const { services } = useHubServices();

  const getDefaultService = useCallback((serviceName: string) => {
    return findService(services.available, serviceName);
  }, [services.available]);

  return getDefaultService;
};

export default useHubDefaultService;
