/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useEffect, useMemo, useRef, useState } from 'react';
import Icon from '@ant-design/icons';
import classNames from 'classnames';
import { Tooltip } from 'antd';
import { DefaultVariantId, HubServiceState } from '@kemu-io/kemu-core/types';
import { SerializableServiceInfo } from '@kemu-io/hs-types';
import { useDebouncedCallback } from 'use-debounce';
import { GetPortsInformationFunction, GateUI, GateUIProps } from '../index';
import { calculateWidgetColors } from '../common';
import styles from './hubService.module.css';
import HsUIWrapper from './HsUIWrapper/HsUIWrapper';
import { ReactComponent as DefaultServiceIcon } from '@src/assets/img/gates/hubService.svg';
import useReactiveWidgetState from '@hooks/useReactiveWidgetState';
import useKemuHubLink from '@hooks/useHubLink';
import { PortLocation } from '@src/types/canvas_t';
import InjectSvg from '@components/InjectSvg/InjectSvg';
import useServiceUIContents from '@hooks/useServiceUIContents';
import useCompatibleHubService from '@hooks/useCompatibleHubService';

const DefaultWidgetHeight = 64;

const docStyle = getComputedStyle(document.body);
const DefaultServiceColor =  docStyle.getPropertyValue('--kemu-color-widget-type-custom');
// const cache = emotionCache({key: 'widget-hub-service-a'});

const calculateWidgetHeight = (inputs: number | undefined, outputs: number | undefined): number => {
	// 10 = extra pixels per added port
	const portsHeight = ((Math.max(inputs || 1, outputs || 1)) * 17);
	return portsHeight;
};

const TooltipInfo = (props: {
	name: string,
	version: string,
	service?: SerializableServiceInfo | null,
}): React.JSX.Element => {

	const { service, name, version } = props;
	return (
		<div className={styles.Tooltip}>
			<div className={styles.Header}>{name} (v{version})</div>
			{service && (
				<div className={styles.Description}>{service.description}</div>
			)}
		</div>
	);
};

const calculateStrChecksum = async (input: string): Promise<string> => {
	const encoder = new TextEncoder();
	const data = encoder.encode(input);
	const hash = await window.crypto.subtle.digest('SHA-256', data);
	const hashArray = Array.from(new Uint8Array(hash));
	const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
	return hashHex;
};


const HubServiceWidget = (props: GateUIProps): React.JSX.Element => {
	const { recipeId, thingRecipeId, info, repaint } = props;
	const { id: widgetId } = info;
	// const { getState } = useWidgetState<HubServiceState>(recipeId, thingRecipeId, widgetId);
	const [state, setWidgetState] = useReactiveWidgetState<HubServiceState>(recipeId, thingRecipeId, info.id/* , compareStates */);
	// const t = useTranslation('Widget.HubService');
	const { connector, status } = useKemuHubLink();
	// const isAcknowledged = status === 'acknowledged';
	// const services = connector.getCachedServices();
	const serviceVersion = state.service?.version;
	const containerRef = useRef<HTMLDivElement>(null);
	const [manifestChecksum, setManifestChecksum] = useState<string | undefined>();
	const serviceName = state.service?.name;
	const serviceManifest = useCompatibleHubService(serviceName, serviceVersion);
	const widgetContents = useServiceUIContents(serviceName, serviceManifest?.version);
	// const serviceManifest = useMemo(() => {
	// 	const matching = services.find((service) =>
	// 		service.name === serviceName
	// 		&& service.version === serviceVersion);

	// 	// if (matching) {
	// 	// 	lastFoundServiceManifest.current = matching;
	// 	// }
	// 	return matching;
	// }, [services, serviceName, serviceVersion]);

	// NOTE: We choose the manifest first in case the color changed
	const widgetColor = serviceManifest?.color || state.service?.color || DefaultServiceColor;
	const disabled = !!info.disabled;
	const serviceOffline = !serviceManifest;
	const hubOffline = status === 'disconnected';

	const colors = useMemo(() => {
    if (!widgetColor) { return undefined; }

    const docStyle = getComputedStyle(document.body);
    const disabledColor = docStyle.getPropertyValue('--kemu-color-disabled-widget');

    const colors = calculateWidgetColors(disabled || serviceOffline ? disabledColor : widgetColor);
    return colors;
  }, [widgetColor, disabled, serviceOffline]);

	const portsHeight = calculateWidgetHeight(state.service?.inputs?.length, state.service?.outputs?.length);


	const updateWidgetManifestInfo = useDebouncedCallback(async () => {
		if (!serviceManifest) { return; }

		const {
			uiContentChecksum,
			...contentLessManifest
		} = serviceManifest;
		const manifestStr = JSON.stringify(contentLessManifest);
		const checksum = await calculateStrChecksum(manifestStr);

		setManifestChecksum((old) => {
			setWidgetState((c) => {
				if (old !== checksum) {
					return {
						...c,
						service: contentLessManifest,
						$$$serviceId: serviceManifest.sessionId,
					};
				} else if (c.$$$serviceId !== serviceManifest.sessionId) {
					// Update the running session id
					return {
						...c,
						$$$serviceId: serviceManifest.sessionId,
					};
				}

				return c;
			});

			return checksum;
		});
	}, 100);

	// Keeps track of manifest file changes (inputs, color, name, etc)
	// and update the service state accordingly, this forces a new re-mounting
	// of the widget.
	useEffect(() => {
		if (serviceManifest) {
			updateWidgetManifestInfo();
		}

		return () => {
			updateWidgetManifestInfo.cancel();
		};
	}, [serviceManifest, updateWidgetManifestInfo]);

	// Custom UI
	if (widgetContents && state.service) {
		return (
			<HsUIWrapper
				hidden={!!props.hidden}
				recipeId={recipeId}
				repaintPorts={repaint}
				serviceSessionId={state.$$$serviceId}
				manifest={state.service}
				uiContent={widgetContents}
				widgetId={widgetId}
				contentsChecksum={serviceManifest?.uiContentChecksum}
				manifestChecksum={manifestChecksum}
				disabled={disabled}
				finalColor={widgetColor}
				serviceOnline={!!serviceManifest}
			/>
		);
	}

	return (
		<div className={classNames({
			[styles.Invisible]: props.hidden
		})}>
			<div
				style={{
					...(!disabled && !serviceOffline && !hubOffline && colors?.content ? {
						backgroundColor: colors.content,
					} : {}),
					borderColor: colors?.header,
					minHeight: `${DefaultWidgetHeight}px`,
					...(portsHeight > DefaultWidgetHeight ? {
						height: `${portsHeight}px`,
					} : {}),
				}}
				className={classNames(styles.GateBody, {
					[styles.WidgetDisabled]: disabled || serviceOffline || hubOffline,
				})}
			>
				{state.service?.shortTitle && serviceOffline && (
					<div
						style={{
							color: colors?.text,
						}}
						className={styles.BarTitle}
					>
						(offline)
					</div>
				)}

				{state.service && (
					<Tooltip
						mouseEnterDelay={1}
						title={
							<TooltipInfo
								name={state.service?.title || state.service.name}
								version={state.service.version}
								service={serviceManifest || state.service}
							/>
						}
					>
						<div className={styles.Icon} ref={containerRef}>
							{state.service.svgIcon ? (
								<InjectSvg
									contents={state.service.svgIcon}
									width={32}
									height={32}
									targetElement={containerRef.current}
									fill={colors?.text}
								/>
							) : (
								<Icon component={DefaultServiceIcon} />
							)}
						</div>
					</Tooltip>
				)}

				<div className={classNames(styles.BottomCaption, {
					[styles.Hidden]: props.hidden,
					[styles.InsideGroup]: !!props.info.groupId,
				})}>
					{state.service?.shortTitle || state.service?.title || state.service?.name}
				</div>
			</div>
		</div>
	);
};


const getPortsInformation: GetPortsInformationFunction<HubServiceState> = (state) => {
	// const portContext: WidgetPortContext = {
	// 	recipePoolId: widgetInfo.recipePoolId,
	// 	recipeType: widgetInfo.recipeType,
	// 	thingRecipeId: widgetInfo.thingRecipeId,
	// 	widgetId: widgetInfo.id,
	// };

	const portSize = 0.02;

	const getPositionFromIndex = (totalPorts: number, index: number, isInput: boolean): PortLocation => {
		const topPadding = totalPorts <= 12 ? 0.15 : 0.05;
		const inputFraction = ((1 - (topPadding + portSize)) / totalPorts);
		let space = (inputFraction * index) + topPadding;
		if (totalPorts === 1) { space = 0.5; }
		if (totalPorts === 2) { space = 0.30 + (0.4 * index); }
		if (totalPorts === 3) { space = 0.25 + (0.25 * index); }
		if (totalPorts === 4) { space = 0.22 + (0.19 * index); }
		if (totalPorts === 5) { space = 0.20 + (0.16 * index); }
		if (totalPorts === 6) { space = 0.20 + (0.15 * index); }

		return [isInput ? 0 : 1, space, isInput ? -1 : 1, 0];
	};

	const variantId = state.variantId;
	const matchingVariant = state.service?.variants?.find((v) => v.id === state.variantId);

	const defaultInputs = variantId ?
		state.dynamicInputs[variantId] || matchingVariant?.inputs || []
		: state.dynamicInputs[DefaultVariantId] || state.service?.inputs || [];

	const defaultOutputs = variantId ?
		state.dynamicOutputs[variantId] || matchingVariant?.outputs || []
		: state.dynamicOutputs[DefaultVariantId] || state.service?.outputs || [];


	return {
		outputs: defaultOutputs.map((output, i) => {
			return  {
				name: output.name,
				type: output.type,
				position: getPositionFromIndex(defaultOutputs.length, i, false),
				...(output.jsonShape ? { jsonShape: output.jsonShape } : undefined),
				...(output.label ? { label: output.label } : undefined),
			};
		}),

		inputs: defaultInputs.map((input, i) => {
			return  {
				name: input.name,
				type: input.type,
				position: getPositionFromIndex(defaultInputs.length, i, true),
				...(input.jsonShape ? { jsonShape: input.jsonShape } : undefined),
				...(input.label ? { label: input.label } : undefined),
			};
		})
	};
};


export default {
	getPortsInformation,
	// BarIcon: GateBarIcon,
	Element: HubServiceWidget,
	getWrapperClass: () => styles.WidgetWrapper,
	// CustomSettingsDialog: GateCustomSettings,
	// hasTitle: true,
	// getWidgetTitle: (intl) => intl.formatMessage({ defaultMessage: 'Hub Service', id: 'Widget.HubService.DefaultTitle' }),
} as GateUI;
