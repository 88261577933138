
import React, { KeyboardEventHandler, useEffect, useRef, useState, CSSProperties, useMemo } from 'react';
import {
	WidgetGroupState,
 CustomGateState,
  WidgetPortContext,
	WidgetType
} from '@kemu-io/kemu-core/types';
import WidgetGroupProcessor, { getDefaultState } from '@kemu-io/kemu-core/widgets/widgetGroup/index.js';
import { motion } from 'framer-motion';
import { Tooltip } from 'antd';
import { WarningOutlined, BugFilled } from '@ant-design/icons';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import variablesManager from '@kemu-io/kemu-core/common/managers/variablesManager.js';
import { PortLocation } from '../../../types/canvas_t';
import { pushFolderPath } from '../../../features/Workspace/workspaceSlice';
import { setCurrentWidgetAction } from '../../../app/reducers/widget/widgetSlice';
import {
	GetPortsInformationFunction,
	GateUI,
	GateUIProps,
	GateCustomSettingsProps
} from '../index.ts';
import WidgetIcon from './WidgetIcon/WidgetIcon';
import styles from './widgetGroup.module.css';
import WidgetBodySelector from './WidgetBodySelector/WidgetBodySelector';
import GroupFieldsRenderer from './GroupFieldsRenderer/GroupFieldsRenderer';
import GroupHeader from './GroupHeader/GroupHeader';
import useReactiveWidgetState from '@hooks/useReactiveWidgetState';
import { ABORT_OPEN_WIDGET_CLICK, SETTINGS_CONTAINER_CLASS, WIDGET_GROUP_FIELDS_CONTAINER_CLASS } from '@common/constants';
import { calculateWidgetColors } from '@components/gates/common';
import GateSettingsCloseBtn from '@components/gateSettingsCloseBtn/gateSettingsCloseBtn';
import useTranslation from '@hooks/useTranslation';
import { getGroupInnerWidgets } from '@src/app/recipe/utils';


type Props = GateUIProps

const animationVariants = {
  opening: { opacity: 0, scale: 5 },
  closed: { opacity: 1, scale: 1 },
};

const WidgetGroup = (props: Props): React.JSX.Element => {
	const dispatch = useDispatch();
	const t = useTranslation('WidgetGroup');
	const thisWidgetId = props.info.id;
	const [state, setState] = useReactiveWidgetState<WidgetGroupState>(props.recipeId, props.thingRecipeId, thisWidgetId);
	const [editing, setEditing] = useState(false);
	const inputRef = useRef<HTMLInputElement | null>(null);
	const containerRef = useRef<HTMLDivElement | null>(null);
	const [isOpen, setIsOpen] = useState(false);

	const innerWidgets = getGroupInnerWidgets(props.recipeId, props.thingRecipeId, thisWidgetId);
	const hasInnerBundlesWithErrors = innerWidgets.some((widget) => {
		if (widget.type !== WidgetType.widgetBundle) { return false; }
		const widgetState = widget.state || {};
		const initError = widgetState.$$initializationError;
		const missingProcessor = !widgetState.$$processor;

		return initError || missingProcessor;
	});

	const fixedState = {
		...getDefaultState(),
		...state
	};

	const styledBody: CSSProperties = fixedState.color && !props.info.disabled ? {
		backgroundColor: fixedState.color
	} : {};

	const handleEditName = (event: React.MouseEvent<HTMLLabelElement> ) => {
		event.stopPropagation();
		setEditing(true);
	};

	const handleAnimationComplete = () => {
		if (isOpen) {
			// Set the id of the widget being edited in the widget slice or clear it out
			dispatch(setCurrentWidgetAction(fixedState.collectionInfo?.widgetId || null));


			// Set the info of the path in the workspace slice
			dispatch(pushFolderPath({
				groupId: props.info.id,
				name: fixedState.name,
				description: fixedState.description,
			}));
		}
	};

	const handleDoubleClick = (event: React.MouseEvent) => {
		// Prevent opening the widget if users dbl click on a dynamic field
		const hasAbortClass = (event.target as HTMLElement)?.closest(`.${ABORT_OPEN_WIDGET_CLICK}`);
		if (!hasAbortClass) {
			setIsOpen(true);
		}
	};

	const handleBlur = () => {
		if (inputRef.current) {
			const newName = inputRef.current.value.trim();
			if (newName) {
				setState({
					...state,
					name: newName
				});
			}
		}
		setEditing(false);
	};

	const handleKeyDown: KeyboardEventHandler = (event) => {
		if (event.key === 'Enter') {
			handleBlur();
		}
	};

	useEffect(() => {
		if (editing && inputRef.current) {
			inputRef.current.select();
		}
	}, [editing]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const settings = fixedState.settings || [];
	// 44 = Default height is 54
	// 10 = extra pixels per added port
	const portsHeight = 44 + ((Math.max(fixedState.inputs.length, fixedState.outputs.length)) * 10);
	const visibleFields = settings.filter((setting) => setting.customSettingField === false);
	const hiddenFields = settings.filter((setting) => setting.customSettingField === true);
	const hasVisibleFields = visibleFields.length > 0;
	const hasHiddenFields = hiddenFields.length > 0;
	const hasAnyFields = hasVisibleFields || hasHiddenFields;
	const hasMultiplePorts = fixedState.inputs.length > 2 || fixedState.outputs.length > 2;
	const totalInputsAndOutputs = fixedState.inputs.length + fixedState.outputs.length;
	const totalVisiblePorts = visibleFields.length;

	// Prevents saving widget with variables that have the same name as other widgets
	const hasRepeatedVariables = useMemo(() => {
		const variables = variablesManager.getVariableNames(
      props.recipeId,
      props.thingRecipeId,
			thisWidgetId,
    );

		const repeatedVariables = settings.find((s) => {
			// Find any other widget other than this using the same var name
			const matchingVar = variables.find((v) =>
				v.name === s.variableName
				&& v.ownerWidgetId !== thisWidgetId
			);

			return Boolean(matchingVar);
		});

		return Boolean(repeatedVariables);
	}, [settings, thisWidgetId, props.recipeId, props.thingRecipeId]);

	// Adjust the height of the body based on the number of ports or visible fields
	useEffect(() => {
		if (containerRef.current) {
			const fieldsContainer = containerRef.current.querySelector(`.${WIDGET_GROUP_FIELDS_CONTAINER_CLASS}`);
			if (fieldsContainer) {
				const fieldsHeight = fieldsContainer.clientHeight;
				if (fieldsHeight > portsHeight) {
					containerRef.current.style.height = `auto`;
				} else {
					containerRef.current.style.height = `${portsHeight}px`;
				}
			}
		}
	}, [totalVisiblePorts, totalInputsAndOutputs, portsHeight]);

	return (
		<motion.div
			animate={isOpen ? 'opening' : 'closed'}
			variants={animationVariants}
			transition={{ duration: 0.25 }}
			onAnimationComplete={handleAnimationComplete}
		>
			<div
				className={styles.GateBody}
				onDoubleClick={handleDoubleClick}
				ref={containerRef}
				style={{
					height: hasAnyFields && !hasMultiplePorts ? 'auto' : portsHeight,
				}}
			>
				{fixedState.type === 'custom' ? (
					<div
						style={styledBody}
						className={classNames(styles.WidgetTypeContainer, {
							[styles.HasVisibleFields]: hasVisibleFields,
							[styles.HasHiddenFields]: hasHiddenFields,
							[styles.HasMultiPort]: hasMultiplePorts,
							[styles.CustomSettingsVisible]: props.customSettingsVisible,
						})}
					>
						<WidgetBodySelector
							name={fixedState.name}
							widgetId={props.info.id}
							recipeId={props.recipeId}
							thingRecipeId={props.thingRecipeId}
							color={fixedState.color}
							disabled={props.info.disabled}
							icon={fixedState.icon}
							settings={fixedState.settings}
							totalInputs={fixedState.inputs.length}
							totalOutputs={fixedState.outputs.length}
							onOpenCustomSettings={props.openCustomSettings}
						/>

						{/* Show Warning */}
						{hasRepeatedVariables && (
							<div className={styles.VariableInUseWarning}>
								<Tooltip title={t('VariableInUseWarning', 'VariableInUseWarning')} placement='bottom'>
									<WarningOutlined />
								</Tooltip>
							</div>
						)}

						{hasInnerBundlesWithErrors && (
							<div className={styles.WidgetWithBugs}>
								<Tooltip title={t('BundleWithErrors', 'WidgetGroup.BundleWithErrors')} placement='bottom'>
									<BugFilled />
								</Tooltip>
							</div>
						)}

						{fixedState.collectionInfo !== undefined && (
							<div className={styles.VersionContainer}>
								v{fixedState.collectionInfo?.version}
							</div>
						)}
					</div>
				) : (
					// NOTE: as of 14/Jan/2024, this case is not used.
					<div className={styles.FolderTypeContainer}>
						<WidgetIcon icon="folder" />
					</div>
				)}

				{/* Label underneath */}
				{(!hasVisibleFields && !hasHiddenFields) ? (
					<div className={classNames(styles.Label, { editing })}>
						{!editing ? (
							<Tooltip title={fixedState.description} placement="bottom" mouseEnterDelay={0.8}>
								<label onDoubleClick={handleEditName}>{fixedState.name}</label>
							</Tooltip>
						) : (
							<input ref={inputRef} onKeyDown={handleKeyDown} onBlur={handleBlur} defaultValue={fixedState.name} />
						)}
					</div>
				) : (
					// Show a standard caption
					<div className={classNames(styles.Label, styles.NotEditable)}>
						{fixedState.name}
					</div>
				)}

			</div>
		</motion.div>
	);
};

const getPortsInformation: GetPortsInformationFunction = (state: CustomGateState<WidgetGroupState>, widgetInfo) => {
	const portContext: WidgetPortContext = {
		recipePoolId: widgetInfo.recipePoolId,
		recipeType: widgetInfo.recipeType,
		thingRecipeId: widgetInfo.thingRecipeId,
		widgetId: widgetInfo.id,
	};
	const outputNames = WidgetGroupProcessor.getOutputNames(state, portContext);
	const inputNames = WidgetGroupProcessor.getInputNames(state, portContext);

	const onlyOuterOutputs = outputNames.filter(port => !port.isInner);
	const onlyOuterInputs = inputNames.filter(port => !port.isInner);

	const portSize = 0.02;

	const getPositionFromIndex = (total: number, index: number, isInput: boolean): PortLocation => {
		const topPadding = total <= 12 ? 0.15 : 0.05;
		const inputFraction = ((1 - (topPadding + portSize)) / total);
		let space = (inputFraction * index) + topPadding;
		const totalPorts = total + (state.useTriggerPort ? 1 : 0);
		if (totalPorts === 1) { space = 0.5; }
		if (totalPorts === 2) { space = 0.30 + (0.4 * index); }
		if (totalPorts === 3) { space = 0.25 + (0.25 * index); }
		if (totalPorts === 4) { space = 0.27 + (0.17 * index); }
		if (totalPorts === 5) { space = 0.20 + (0.15 * index); }
		if (totalPorts === 6) { space = 0.20 + (0.13 * index); }

		return [isInput ? 0 : 1, space, isInput ? -1 : 1, 0];
	};

	return {
		outputs: onlyOuterOutputs.map((output, i) => {
			return  {
				name: output.name,
				type: output.type,
				position: getPositionFromIndex(onlyOuterOutputs.length, i, false),
				...(output.jsonShape ? { jsonShape: output.jsonShape } : undefined),
				...(output.label ? { label: output.label } : undefined),
			};
		}),

		inputs: onlyOuterInputs.map((input, i) => {
			return  {
				name: input.name,
				type: input.type,
				position: getPositionFromIndex(onlyOuterInputs.length, i, true),
				...(input.jsonShape ? { jsonShape: input.jsonShape } : undefined),
				...(input.label ? { label: input.label } : undefined),
			};
		})
	};
};

const GateCustomSettings = (props: GateCustomSettingsProps): React.JSX.Element => {
	const [state] = useReactiveWidgetState<WidgetGroupState>(props.recipeId, props.blockId, props.gateInfo.id);
	const { onClose } = props;

	const fixedState = {
		...getDefaultState(),
		...state
	};

	const widgetColor = fixedState.color;
	const disabled = props.gateInfo.disabled;

	const colors = useMemo(() => {
    if (!widgetColor) { return undefined; }

    const docStyle = getComputedStyle(document.body);
    const disabledColor = docStyle.getPropertyValue('--kemu-color-disabled-widget');

    const colors = calculateWidgetColors(disabled ? disabledColor : widgetColor);
    return colors;
  }, [widgetColor, disabled]);

	const hiddenFields = (fixedState.settings || []).filter((setting) => setting.customSettingField === true);
	const maxPorts = Math.max(fixedState.inputs.length, fixedState.outputs.length);
	const multiPort = maxPorts > 2;

	return (
		<div
			className={classNames(styles.SettingsContainer, SETTINGS_CONTAINER_CLASS)}
			style={{
				backgroundColor: colors?.content,
				borderColor: colors?.header
			}}
		>
			<div
				className={styles.SettingsHeader}
				style={{
					backgroundColor: colors?.header
				}}
			>
				<GroupHeader
					showGearIcon={false}
					title={fixedState.name}
					headerColor={colors?.header}
					isBrightColor={colors?.isBrightColor}
					className={classNames(styles.Header, {
						[styles.LongName]: fixedState.name.length >= 20
					})}
				/>
				<GateSettingsCloseBtn
					onClose={onClose}
				/>
			</div>

			<div className={styles.SettingsContent}>
				<GroupFieldsRenderer
					repaintWidgetPorts={props.repaintPorts}
					recipeId={props.recipeId}
					thingRecipeId={props.blockId}
					widgetId={props.gateInfo.id}
					color={fixedState.color}
					disabled={props.gateInfo.disabled}
					fields={hiddenFields}
					multiPort={multiPort}
					isBrightColor={colors?.isBrightColor}
					isInnerSetting={true}
				/>
			</div>
		</div>
	);
};

export default {
	getPortsInformation,
	Element: WidgetGroup,
	CustomSettingsDialog: GateCustomSettings,
	getWrapperClass: () => styles.GateWrapper,
	// WidgetGroups handle titles themselves
	hasTitle: false,
	hideLabelsOnSettingsOpen: true,
} as GateUI;
