import React, { useCallback, useEffect, useState } from 'react';
import Radio from 'antd/lib/radio';
import Layout from 'antd/lib/layout';
import Button from 'antd/lib/button';
import theme from 'antd/lib/theme';
import Icon from '@ant-design/icons';
import CloseOutlined from '@ant-design/icons/CloseOutlined';
// import PlusOutlined from '@ant-design/icons/PlusOutlined';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import MyRecipesList from '../../marketplace/RecipeList/RecipeList';
import PageModal from '../../pageModal/pageModal';
import AlgoliaSearchBox from '../../marketplace/AlgoliaSearchBox/AlgoliaSearchBox';
import { selectSignedUserProfile } from '../../../app/reducers/user/userSlice';
import { ReactComponent as KemuLogoAndText } from '../../../assets/img/kemuAndText.svg';
import MarketplaceMenu from './MarketplaceMenu/MarketplaceMenu';
import styles from './marketplace.module.css';
import useCurrentWorkspacePath from '@common/hooks/useCurrentWorkspacePath';
import useTranslation from '@hooks/useTranslation';
import routes from '@common/routes/index';
import globals from '@common/globals';
// import 'antd/dist/antd.min.css';
import '../core.css';
import useQueryString from '@hooks/useQueryString';
import SearchInputContextProvider, { SearchInputContextType } from '@src/contexts/SearchInputContext';
import HubServicesScreen from '@components/marketplace/HubServicesScreen/HubServicesScreen';

const { Header, Content } = Layout;

export const MARKETPLACE_CONFIG_NAME = 'marketplace';
export interface MarketplaceCacheState {
	myRecipes: number;
	myTutorials: number;
	publicRecipes: number;
	publicThings: number;
}

export const MarketplaceCacheDefaultState: MarketplaceCacheState = {
	myRecipes: 5,
	publicRecipes: 5,
	myTutorials: 5,
	publicThings: 10,
};

const HorizontalTab = (props: {
	targetPath: string,
	selected: boolean,
	label: string
}): React.JSX.Element => {
	const navigate = useNavigate();

	const navigateToTab = () => {
		navigate(props.targetPath);
	};

	return (
		<>
			<Radio.Button
				onClick={navigateToTab}
				type="link"
				className={`my-recipes-tab ${styles.SubCategoryBarBtn} ${props.selected ? styles.SelectedSubCategory : ''}`}
			>
				{props.label}
			</Radio.Button>
		</>
	);
};

const MarketplaceLayout = (): React.JSX.Element => {
	const navigate = useNavigate();
	const { tokens } = useSelector(selectSignedUserProfile);
	// const { token: { colorPrimary } } = theme.useToken();
	// const inMarketplaceRoot = useMatch({ path: routes.marketplace.getMarketplaceRoute(), end: true });
	const t = useTranslation('Marketplace');
	const getWorkspacePath = useCurrentWorkspacePath();
	const queryStr = useQueryString();
	const inMyRecipes = queryStr.get('tab') === 'recipe';
	const inHubServices = queryStr.get('tab') === 'hub-services';
	// const inMyRecipes = useMatch({ path: routes.marketplace.getMyRecipesRoute(), end: true });

	// const inPublicRecipes = useMatch({ path: routes.marketplace.getPublicationListRoute('recipe') });
	// const inPublicThings = useMatch({ path: routes.marketplace.getPublicationListRoute('thing') });
	const [searchContext, setSearchContext] = useState<SearchInputContextType>({});

	const handleSetSearchContext = useCallback((context: SearchInputContextType) => {
		setSearchContext(context);
	}, [setSearchContext]);

	const closeMarketplace = useCallback(() => {
		const workspacePath = getWorkspacePath();
		navigate(workspacePath);
	}, [getWorkspacePath, navigate]);


	const handleCreateRecipe = useCallback(() => {
		window.open(`https://${globals.KEMU_APP_DOMAIN}`, '_blank');
	}, []);

	// If no route defined, navigate to my recipes by default
	// useEffect(() => {
	// 	if (inMarketplaceRoot) {
	// 		navigate(routes.marketplace.getMyRecipesRoute());
	// 	}
	// }, [inMarketplaceRoot, navigate]);



	// const MarketplaceTabs = (): React.JSX.Element => {
	// 	return (
	// 		<>
	// 			<Radio.Button
	// 				onClick={() => navigateToTab(routes.marketplace.getPublicationListRoute('recipe'))}
	// 				type="link"
	// 				className={`public-recipes-tab ${styles.SubCategoryBarBtn} ${inPublicRecipes ? styles.SelectedSubCategory : ''}`}
	// 			>
	// 				{t('Navigation.PublicRecipes', 'Public Recipes')}
	// 			</Radio.Button>
	// 		</>
	// 	);
	// };


	return (
		<PageModal visible={true} fullScreen={true}>
			<div id="layout-container" className="light-theme marketplace">
				<Layout className="site-layout">
					<Header
						// className={`site-layout-background ${styles.Header}`}
						className={styles.Header}
						style={{
							// backgroundColor: colorPrimary,
						}}
					>
						<Icon component={KemuLogoAndText} className={styles.HeaderLogo} />
						<Button data-kemu-meta="close-marketplace-btn" type="text"className={styles.BackButton} onClick={closeMarketplace} icon={<CloseOutlined style={{ fontSize: 24 }}/>} />
					</Header>


					<Layout>
						{/* Left Menu */}
						<MarketplaceMenu />
						{/* Wait for the user token to be provided before rendering anything */}
						{!!tokens && (
							<SearchInputContextProvider value={searchContext}>
								<Content>
									<div className={`${styles.Section} section`}>
										<div className={`subcategory-bar `} id={styles.SubCategoryBar}>
											<Radio.Group value='large'>
												{inMyRecipes && <HorizontalTab targetPath={routes.marketplace.getMyRecipesRoute()} selected={inMyRecipes} label={t('Navigation.MyRecipes', 'My Recipes')} />}
											</Radio.Group>

											{(inMyRecipes || inHubServices) && (
												<div data-kemu-meta={'search-box'} className={styles.SearchBoxContainer}>
													<AlgoliaSearchBox
														placeholder={inMyRecipes ?
															t('MyRecipes.SearchPlaceholder', 'Search for recipes...') :
															t('HubServices.SearchPlaceholder', 'Search for services...')
														}
													/>
												</div>
											)}
										</div>

											{/* Space at the top of the header */}
										<div className={`${styles.SectionHeader}`} />

										<div className={styles.SectionBody}>
											{inMyRecipes && <MyRecipesList onSetSearchContext={handleSetSearchContext} />}
											{inHubServices && <HubServicesScreen onSetSearchContext={handleSetSearchContext} />}
											{/* <Route path={routes.marketplace.getPublicationListRoute('recipe')} component={PublicRecipes} exact={true}/> */}
											{/* <Route path={routes.marketplace.getGenericPublicationDetailsRoute()} component={PublicationDetailsView} exact={true} /> */}
											{/* <Route path={routes.marketplace.getMyTutorialsRoute()} component={MyTutorials} exact={true} /> */}

											{/* <Route
												path={routes.marketplace.getPublicationListRoute('thing')}
												component={PublicThings}
												exact={true}
											/> */}

										</div>
									</div>
								</Content>
							</SearchInputContextProvider>
						)}
					</Layout>
				</Layout>
			</div>
		</PageModal>
	);
};

export default MarketplaceLayout;
