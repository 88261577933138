/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { UserEntity, UserIntegrations, UserPropertyValue, UserTokens } from '@kemu-io/kemu-types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { AsyncRequestStatus, AsyncState } from '../../../types/core_t';
import { UserAccountState, WebRTCTokenPair } from '../../../types/user';
import { trackUserEventReducer } from './trackEventReducer';
import { fetchUserProfileReducer } from './fetchUserReducer';
import { updateUserSettingsReducer } from './updateUserSettingsReducer';
import { updateUserOnboardingReducer } from './updateUserOnboardingReducer';
import { fetchWebRTCSessionTokenReducer } from './fetchWebRTCTokenReducer';

export type UserInfo = {
	profile?: UserEntity;
	tokens?: UserTokens;
	apiKey?: string;
	accountState?: UserAccountState;
	integrations: UserIntegrations;
	asyncState: AsyncState;
}

export interface CustomerState {
	currentUserProfile: UserInfo,
	webRTCSession:{
		token?: WebRTCTokenPair;
		asyncState: AsyncState;
	}
}

export type LogicMapperDockingMode = 'left' | 'right' | 'full';

export type UserSettings = {
	logicMapperDocking: LogicMapperDockingMode;
	useWidgetBundles?: boolean;
};

/** List of onboarding videos */
export type UserOnboarding = {
	interface?: boolean;
	widgetSettings?: boolean;
	customWidgets?: boolean;
	widgetsCollection?: boolean;
	sharing?: boolean;
}


export type PropertiesMap = Record<string, UserPropertyValue>;
// Action:Noun
export type UserActionEvent = `${string}:${string}`;

const defaultAsyncState: AsyncState = { status: AsyncRequestStatus.idle };

const initialState: CustomerState = {
	currentUserProfile: {
		asyncState: defaultAsyncState,
		integrations: {}
	},

	webRTCSession: {
		asyncState: defaultAsyncState
	},
};


export const marketplaceSlice = createSlice({
	name: 'customer',
	initialState,
	reducers: {
		clearWebRTCSession: (state) => {
			state.webRTCSession.asyncState.status = AsyncRequestStatus.idle;
			state.webRTCSession.asyncState.error = undefined;
			state.webRTCSession.token = undefined;
		},
		setUserProfile: (state, action: PayloadAction<UserEntity>) => {
			state.currentUserProfile.profile = action.payload;
		}
	},

	extraReducers: (builder) => {
		trackUserEventReducer(builder);
		fetchUserProfileReducer(builder);
		fetchWebRTCSessionTokenReducer(builder);
		updateUserSettingsReducer(builder);
		updateUserOnboardingReducer(builder);
	}
});


export const {
	clearWebRTCSession,
	setUserProfile
} = marketplaceSlice.actions;


export const selectSignedUserProfile = (state: RootState): UserInfo => state.user.currentUserProfile;
export const webRTCSession = (state: RootState) => state.user.webRTCSession;
export const selectUserIntegrations = (state: RootState) => state.user.currentUserProfile.integrations;
export const selectUserSettings = (state: RootState): UserSettings | null =>
	state.user.currentUserProfile.integrations.posthog?.properties?.settings as UserSettings || null;

export const selectUserOnboarding = (state: RootState): UserOnboarding | null =>
	state.user.currentUserProfile.integrations.posthog?.properties?.onboarding as UserOnboarding || null;

export default marketplaceSlice.reducer;
