/* eslint-disable @typescript-eslint/no-explicit-any */
import { DataType, ImageDataLike, KemuHubCommand, KemuObjectWrapper } from '@kemu-io/hs-types';
import { createImageDataFromInfo } from '@kemu-io/kemu-core/common/utils';

/**
 * Invokes the callback if the given command is an ACK request.
 * @returns the service id if the command is an ACK request, otherwise null.
 */
const onAckRequest = (command: string, cb?: (serviceId?: number) => void): number | null => {
  const sPrefix = KemuHubCommand.SocketAcknowledge;
  const iPrefix = KemuHubCommand.IpcAcknowledge;
  const isSocket = command.startsWith(sPrefix);
  const isIpc = command === iPrefix;

  if (isIpc) {
    cb?.();
    return 0;
  }

  if (isSocket) {
    const sp = command.split(isSocket ? sPrefix : iPrefix);
    const serviceId = parseInt(sp[1]);
    cb?.(serviceId);
    return serviceId;
  }

  return null;
};

/**
 * Invokes the callback if the given command is a `ServicesListChanged` event.
 * @param command the command to analyze
 * @param cb the callback to invoke if the command is a `ServicesListChanged` event
 * @returns true if the command is a `ServicesListChanged` event, otherwise false.
 */
const onServicesListChanged = (command: string, cb?: () => void): boolean => {
  if (command === KemuHubCommand.ServicesListChanged) {
    cb?.();
    return true;
  }

  return false;
};

/**
 * Creates an ACK response string for a given service id.
 * @returns a string with format: `ackr:<serviceId>:<apiKey>`
 */
const buildAckResponse = (serviceId: number, apiKey: string): string => {
  return `${KemuHubCommand.AcknowledgeResponse}${serviceId}:${apiKey}`;
};

/**
 * Converts inner properties in the object into the correct type as pointed by their `_kemuType` property.
 * @param rawObj the raw object to convert. This object will be mutated.
 * @param maxNestedLevels the maximum number of nested levels to traverse. Default is 2.
 * @returns the mutated object.
 */
const rebuildKemuTypes = async <T=any>(rawObj: T, maxNestedLevels=2): Promise<T> => {

  // Traverse the first two levels of the object and transform type wrappers
  const traverse = async (obj: any, currentLevel: number) => {
    // Handle arrays
    if (Array.isArray(obj)) {
      for (let i = 0; i < obj.length; i++) {
        const item = obj[i];
        if (item && typeof item === 'object') {
          if (Array.isArray(item)) {
            // If item is an array, traverse it at next level
            await traverse(item, currentLevel + 1);
          } else {
            // Check if array item itself has _kemuType
            const targetType = item._kemuType;
            if (targetType !== undefined) {
              if (targetType === DataType.ImageData) {
                obj[i] = await createImageDataFromInfo(item as ImageDataLike);
              }
            } else if (currentLevel < maxNestedLevels) {
              // If not a type wrapper, traverse deeper
              await traverse(item, currentLevel + 1);
            }
          }
        }
      }
      return;
    }

    // Handle objects
    if (typeof obj === 'object' && obj !== null) {
      const keys = Object.keys(obj);
      for (const key of keys) {
        const innerObj = obj[key];
        if (innerObj && typeof innerObj === 'object') {
          const targetType = innerObj._kemuType;
          if (targetType !== undefined) {
            // Convert into the appropriate type
            if (targetType === DataType.ImageData) {
              obj[key] = await createImageDataFromInfo(innerObj as ImageDataLike);
            }
          } else if (currentLevel < maxNestedLevels) {
            // Handle nested objects and arrays
            await traverse(innerObj, currentLevel + 1);
          }
        }
      }
    }
  };

  await traverse(rawObj, 1);
  return rawObj;
};

export {
  onAckRequest,
  buildAckResponse,
  onServicesListChanged,
  rebuildKemuTypes,
};
