import classNames from 'classnames';
import React from 'react';
import Tooltip from 'antd/lib/tooltip';
import QuestionCircleOutlined from '@ant-design/icons/QuestionCircleOutlined';
import styles from './styledLabel.module.css';

interface Props {
	text: string | React.ReactNode;
	className?: string;
	helpText?: string;
	helpTrigger?: 'hover' | 'click';
	tooltipColor?: string;
	helpIcon?: React.ReactElement;
	/** margin bottom level */
	mb?: number;
}

const StyledLabel = (props: Props): React.JSX.Element => {
	return (
		<label
			className={classNames(styles.Label, props.className)}
			style={{
				marginBottom: props.mb !== undefined ? `${props.mb}px` : 4,
			}}
		>
			{props.helpText ? (
				<div className={styles.Help}>
					{props.text}
					<Tooltip
						color={props.tooltipColor || '#1d2127'}
						title={props.helpText}
						trigger={props.helpTrigger || 'click'}
					>
						{props.helpIcon ? props.helpIcon : <QuestionCircleOutlined />}
					</Tooltip>
				</div>
			) : (
				props.text
			)}
		</label>
	);
};

export default StyledLabel;
