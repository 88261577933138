import React, { useCallback, useContext } from 'react';
import { KemuHubFunctions, UninstallServiceArgs, UninstallServiceHubResponse } from '@kemu-io/hs-types';
import { Button, Tooltip, Modal, Alert, ModalFuncProps } from 'antd';
import { ExclamationCircleFilled ,
	DeleteFilled,
} from '@ant-design/icons';
import classNames from 'classnames';
import { WidgetLaunchpadContext } from '../../LaunchpadContext';
import useKemuHubLink from '../../../../../../common/hooks/useHubLink';
import styles from './SelectedServiceFooter.module.css';
import useTranslation from '@hooks/useTranslation.ts';
import useGlobalNotification from '@hooks/useGlobalNotification';

export type FooterInfo = {
  name: string;
  title?: string;
  description: React.ReactNode;
  descriptionClassName?: string;
  version?: string;
}

type Props = FooterInfo & {
  clearFooter?: () => void;
  hideUninstallBtn?: boolean;
}

const { confirm } = Modal;


const SelectedServiceFooter = (props: Props) => {
  const { clearFooter, version, name, description, title, descriptionClassName } = props;
  // const { name, version, description } = service || props.widgetInfo;
  const t = useTranslation('WidgetLaunchpad.HubServices');
  const cw = useTranslation('CommonWords');
  const { setPreventClose } = useContext(WidgetLaunchpadContext);
  const { connector } = useKemuHubLink();
  const { showNotification } = useGlobalNotification();


  const handleDelete = useCallback(() => {
    setPreventClose(true);
    if (!version) { return; }

    const modalProps: ModalFuncProps = {
      title: t('Uninstall.Confirm.Title'),
      icon: <ExclamationCircleFilled />,
      content: t('Uninstall.Confirm.Description'),
      okType: 'danger',
      okText: cw('Yes'),
      okButtonProps: { disabled: false },
      cancelText: cw('No'),
    };

    const thisModal = confirm({
      ...modalProps,
      onOk: async () => {
        try {
          thisModal.update({
            ...modalProps,
            okButtonProps: { loading: true },
            cancelButtonProps: { disabled: true, loading: false },
          });

          const args: UninstallServiceArgs = [{
            serviceName: name,
            version,
          }];

          const result = await connector.executeHubFunction<UninstallServiceHubResponse>(KemuHubFunctions.UninstallService, args, {
            timeout: 5 * 60 * 1000, // 5 minutes
          });

          if (result) {
            // show notification
            showNotification({
              type: 'success',
              title: t.withBaseKey('CommonEvents.ServiceUninstalled')('Title').toString(),
              message: t.withBaseKey('CommonEvents.ServiceUninstalled')('Message', 'Message', { service: result[0].service.name }).toString(),
              duration: 5,
            });
          }


          clearFooter?.();
        } catch (e) {
          console.log('Error removing service: ', e);
          // Re turn a promise that never resolves to prevent the modal from closing
          return new Promise(() => {
            thisModal.update({
              ...modalProps,
              title: t('Uninstall.ErrorTitle'),
              content: <Alert
                type='error'
                description={e.message || e.error || typeof e === 'string' ? e : t('Uninstall.UnknownError')}
              />,
              okButtonProps: { disabled: true, loading: false },
            });

            setPreventClose(false);
          });
        }

        setPreventClose(false);
      },
      onCancel: () => {
        setPreventClose(false);
      },
    });
  }, [t, cw, setPreventClose, connector, name, version, clearFooter, showNotification]);

  return (
    <div>
      <div className={styles.FooterTitle}>
        {title || name}
        {version && (
          <span>{' '}(v{version})</span>
        )}
      </div>
      <div className={classNames(styles.FooterDescription, descriptionClassName)}>{description}</div>

      <div className={styles.FooterActionButtons}>
        {!props.hideUninstallBtn && (
          <Tooltip mouseEnterDelay={0.5} title={t('Uninstall.Btn')} placement="right">
            <Button
              onClick={handleDelete}
              type="link"
              danger
              icon={<DeleteFilled />}
            />
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default SelectedServiceFooter;
