import { useCallback, useMemo } from 'react';
import { SearchClient } from 'typesense';
import { useSelector } from 'react-redux';
import { UserTokens } from '@kemu-io/kemu-types';
import globals, { ValidStage } from '@common/globals';
import { selectSignedUserProfile } from '@src/app/reducers/user/userSlice';

const nodes = globals.TYPESENSE_NODES.split(',').map(url => ({ url }));

type SearchConfig = {
  indexName?: string;
  queryBy?: string;
  perPage?: number;
  page?: number;
  groupBy?: string;
  groupLimit?: number;
  filterBy?: string;
  sortBy?: string;
  facetBy?: string;
  noCache?: boolean;
  /**
   * The query to search for. If not provided, the default is `*`.
   */
  query?: string;
};

export type SearchResult<T> = {
  documents: T[];
  total: number;
  page: number;
  pages: number;
};

export type SearchError = {
  error: string;
  code: number;
};

const stageSuffixMap: Record<ValidStage, string> = {
  'stage': 'stage',
  'development': 'dev',
  'production': 'prod',
  'local': 'dev',
  'test': 'test',
};


const getSuffixedCollection = (indexName: string) => {
  return `${indexName}_${stageSuffixMap[globals.STAGE]}`;
};

const useMultiIndexSearch = (config: {
  defaultCollection?: string,
  tokenType?: keyof UserTokens
} = {
  tokenType: 'search',
}) => {
  const { defaultCollection, tokenType } = config;
  const { tokens } = useSelector(selectSignedUserProfile);

  const searchClient = useMemo(() => {
    if (!tokens) { return null; }

    const client = new SearchClient({
      apiKey: tokens[tokenType as keyof UserTokens],
      nodes,
      cacheSearchResultsForSeconds: 10, // cache for 30 seconds
    });

    return client;
  }, [tokens, tokenType]);

  const searchByIndex = useCallback(async <T extends Record<string, any>>(config: SearchConfig | SearchConfig[]): Promise<SearchResult<T>[]> => {
    if (!searchClient) { return [{ documents: [], total: 0, page: 0, pages: 0 }]; }
    const allConfigsHaveIndexName = Array.isArray(config) ? config.every(c => c.indexName) : config.indexName;
    if (!allConfigsHaveIndexName && !defaultCollection) {
      throw new Error('No index name provided');
    }

    const configs = Array.isArray(config) ? config : [config];

    const searches = configs.map(c => ({
      collection: getSuffixedCollection(c.indexName || defaultCollection as string),
      q: c.query || '*',
      query_by: c.queryBy,
      per_page: c.perPage,
      page: c.page,
      filter_by: c.filterBy,
      sort_by: c.sortBy,
      group_by: c.groupBy,
      group_limit: c.groupLimit,
      facet_by: c.facetBy,
      use_cache: !c.noCache,
    }));

    // const results = [response];
    const { results } = await searchClient?.multiSearch.perform<T[]>({
      searches,
    });

    const parsedResults = results.map((result, index) => {
      if ((result as unknown as SearchError).error) {
        console.error(`Error searching ${configs[index].indexName}`, result);
        return {
          documents: [],
          total: 0,
          page: 0,
          pages: 0,
        };
      }

      const hits = result.hits || result.grouped_hits?.[0]?.hits;
      const documents = hits?.map(hit => hit.document) as T[];
      const total = result.found;
      const page = result.page;
      const pages = Math.ceil(total / (result.request_params.per_page ?? 1));
      return {
        documents,
        total,
        page,
        pages,
      };
    });

    return parsedResults;

  }, [searchClient, defaultCollection]);

  const memoizedInstance = useMemo(() => ({
    searchByIndex,
  }), [searchByIndex]);

  return memoizedInstance;
};

export default useMultiIndexSearch;
