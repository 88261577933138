import React from 'react'
import { CheckOutlined, DownloadOutlined } from '@ant-design/icons'
import Button from 'antd/lib/button';
import useTranslation from '@hooks/useTranslation';
import useIsServiceInstalled from '@hooks/useIsServiceInstalled';

export type InstallInfo = {
  installedWithSameVersion: boolean;
  installedWithLowerVersion: boolean;
  installedWithMissingSecrets: boolean;
}

type Props = {
  serviceName: string;
  serviceVersion: string;
  installingService: boolean;
  handleInstallService: (info: {
    installedWithSameVersion: boolean;
    installedWithLowerVersion: boolean;
    installedWithMissingSecrets: boolean;
  }) => void;
}

const HubServiceInstallButton = (props: Props) => {
  const {
    serviceName,
    serviceVersion,
    installingService,
    handleInstallService,
  } = props;

  const t = useTranslation('CommonWords');
  const isServiceInstalled = useIsServiceInstalled();
  const {
    installedWithSameVersion,
    installedWithLowerVersion,
    installedWithMissingSecrets,
    isReady,
  } = isServiceInstalled(serviceName, serviceVersion);

  const alreadyInstalled = installedWithSameVersion || installedWithMissingSecrets || installedWithLowerVersion;


  const handleInstall = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();

    handleInstallService({
      installedWithSameVersion,
      installedWithLowerVersion,
      installedWithMissingSecrets: installedWithMissingSecrets || false,
    });
  }

  if(!isReady) {
    return null;
  }

  return (
    <>
      {installedWithSameVersion && 
        <Button variant="text" color="default" shape="round" icon={<CheckOutlined />} size={'small'}>
          {t('Installed')}
        </Button>
      }
      {installedWithLowerVersion && 
        <Button 
          variant="solid" 
          color="primary" 
          shape="round" 
          icon={<CheckOutlined />} 
          size={'small'} 
          loading={installingService} 
          onClick={handleInstall}
        >
          {t('Update')}
        </Button>
      }
      {installedWithMissingSecrets && 
        <Button variant="text" color="default" shape="round" icon={<CheckOutlined />} size={'small'}>
          {t('Installed')}
        </Button>
      }

      {!alreadyInstalled && 
        <Button 
          variant="solid" 
          color="primary" 
          shape="round" 
          icon={<DownloadOutlined />} 
          size={'small'} 
          loading={installingService} 
          onClick={handleInstall}
        >
          {t('Install')}
        </Button>
      }
    </>
  )
}

export default HubServiceInstallButton