import React, { useState } from 'react';
import InjectSvg from '@components/InjectSvg/InjectSvg';

type Props = {
  /* the raw svg content */
  content: string;
  fill?: string;
  className?: string;
  width?: number;
  height?: number;
}

const SvgFromContent = (props: Props) => {
  const [iconTarget, setIconTarget] = useState<HTMLElement | null>(null);

  return (
    <div
      ref={e => setIconTarget(e)}
      className={props.className}
    >
      <InjectSvg
        width={props.width}
        height={props.height}
        contents={props.content}
        fill={props.fill}
        targetElement={iconTarget}
      />
    </div>
  );
};

export default SvgFromContent;
