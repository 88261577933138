import Collapse from 'antd/lib/collapse';
import React from 'react';
import styles from './PanelTitle.module.css';
import DynamicSVG from '@components/DynamicSVG/DynamicSVG';
import DynamicIcon from '@components/dynamicIcon/dynamicIcon';
import { RequireOnlyOne } from '@src/types/core_t';

type Props = RequireOnlyOne<{
  title: string;
  titleSuffixElement?: React.ReactNode;
  fontAwesomeIcon: string;
  svgIcon: string;
  // panelKey?: string;
  children?: React.ReactNode;
}, 'fontAwesomeIcon' | 'svgIcon'>


const { Panel } = Collapse;

const LaunchpadItemHeader = (props: {
  name: string,
  svgIcon?: string,
  fontAwesomeIcon?: string,
  children?: React.ReactNode,
}) => {
  return (
    <div className={styles.PanelHeader}>
      {props.svgIcon && (
        <DynamicSVG icon={props.svgIcon} size={14} />
      )}

      {props.fontAwesomeIcon && (
        <DynamicIcon iconName={props.fontAwesomeIcon} />
      )}
      <span className={styles.HeaderName}>{props.name}</span>
      {props.children}
    </div>
  );
};

const PanelTitle = (props: Props) => {
  const { titleSuffixElement, fontAwesomeIcon, ...rest } = props;
  return (
    <Panel
      {...rest}
      // key={props.panelKey || (props as any).key}
      key={(rest as any).key}
      className={styles.Panel}
      header={
        <LaunchpadItemHeader
          name={rest.title}
          fontAwesomeIcon={fontAwesomeIcon}
          svgIcon={rest.svgIcon}
        >
          {titleSuffixElement}
        </LaunchpadItemHeader>
      }
    >
      {rest.children}
    </Panel>
  );
};

export default PanelTitle;
