import React from 'react';
import Card from 'antd/lib/card';
import Rate from 'antd/lib/rate';
import Badge from 'antd/lib/badge';
import theme from 'antd/lib/theme';
import Tooltip from 'antd/lib/tooltip';
import SafetyCertificateOutlined from '@ant-design/icons/SafetyCertificateOutlined';
import { MarketplaceRecordWithRatings } from '@kemu-io/kemu-types';
import styles from './HubServiceCard.module.css';
import SvgFromContent from '@components/SvgFromContent/SvgFromContent';
import useTranslation from '@hooks/useTranslation';
import useKemuHubLink from '@hooks/useHubLink';
import useGlobalNotification from '@hooks/useGlobalNotification';
import useInstallMarketplaceItem from '@hooks/useInstallMarketplaceItem';
import HubServiceInstallButton, { InstallInfo } from '../HubServiceInstallButton/HubServiceInstallButton';
import globals from '@common/globals';

type Props = {
  serviceInfo: MarketplaceRecordWithRatings;
  onLoadDetails: (serviceInfo: MarketplaceRecordWithRatings) => void;
};

const marketplacePublicationUrl = globals.MARKETPLACE_PUBLIC_URL;

const getFullAssetPath = (assetPath: string) => {
  return `${marketplacePublicationUrl}/${assetPath}`;
};

const HubServiceCard = (props: Props) => {
  const {
    id,
    title,
    author,
    isKemuOfficial,
    name,
    description,
    // alreadyInstalled,
    // stars,
    // totalReviews,
    rating,
    version,
    mainImage,
    iconSvg,
  } = props.serviceInfo;
  const { token: { colorPrimary } } = theme.useToken();
  const { online } = useKemuHubLink();
  const t = useTranslation('CommonWords');
  const { showNotification } = useGlobalNotification();
  const {installHubService, installingService} = useInstallMarketplaceItem();

  // const stars = 3.5 ;
  // const totalReviews = 0;

  const handleInstallService = async (info: InstallInfo) => {
    const { installedWithSameVersion } = info;
    
    if (installedWithSameVersion) {
      return;
    }

    if(!online) {
      showNotification({
        type: 'error',
        title: t('HubOffline.Title'),
        message: t('HubOffline.RequiredForThisFunctionality'),
        duration: 5,
      });
      return;
    }

    installHubService({
      marketplaceItemId: id,
      title,
    });
  };

  const handleCardClick = () => {
    props.onLoadDetails(props.serviceInfo);
  };

  return (
    <div className={styles.CardContainer} data-kemu-meta={`service-card->${id}`}>
      <Card
        className={styles.ServiceCard}
        onClick={handleCardClick}
        hoverable
        styles={{
          body: {
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          }
        }}
      >
        <div className={styles.Details}>
          <div className={styles.Header}>
            {/* Service Icon */}
            <div className={styles.IconWrapper}>
              <SvgFromContent content={iconSvg.trim()} fill={colorPrimary} width={95} height={95} />
            </div>

            {/* Title, Author, ratings */}
            <div className={styles.TitleSection}>
              <div className={styles.Title}>{title} ({version})</div>
              <div className={styles.Author}>
                {isKemuOfficial && (
                  <Tooltip title={`${t('KemuOfficial')} ${t('Account')}`}>
                    <Badge
                      count={<SafetyCertificateOutlined style={{ color: colorPrimary }} />}
                    />
                  </Tooltip>
                )}
                {author.organizationName}
              </div>

              <div className={styles.Rating}>
                <Rate value={rating.average} disabled allowHalf/>
                <span className={styles.ReviewCount}>({rating.total})</span>
              </div>

              {/* TODO: allow filtering by category Category */}
              {/* <Button
                type="link"
                className={styles.CategoryBtn}
                onClick={handleCategoryClick}
              >
                {category}
              </Button> */}
            </div>

            {/* Install button */}
            <div className={styles.InstallBtnContainer}>
              <HubServiceInstallButton
                serviceName={name}
                serviceVersion={version}
                installingService={installingService}
                handleInstallService={handleInstallService}
              />
            </div>
          </div>

        </div>

        {description && (
          <div className={styles.Description}>{description}{description.trim().endsWith('.') ? '' : '.'}</div>
        )}
        
        <div className={styles.ImageContainer}>
          <img src={getFullAssetPath(mainImage)} alt={title} className={styles.MainImage} />
        </div>
      </Card>
    </div>
  );
};

export default HubServiceCard;
