import React from 'react';
import Card from 'antd/lib/card';
import styles from './HubServiceCardSkeleton.module.css';

const HubServiceCardSkeleton = () => {
  return (
    <div className={styles.CardContainer}>
      <Card
        className={styles.ServiceCard}
        styles={{
          body: {
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          }
        }}
      >
        <div className={styles.Details}>
          <div className={styles.Header}>
            {/* Service Icon Skeleton */}
            <div className={styles.IconWrapper}>
              <div className={styles.IconSkeleton} />
            </div>

            {/* Title, Author, ratings skeleton */}
            <div className={styles.TitleSection}>
              <div className={styles.TitleSkeleton} />
              <div className={styles.AuthorSkeleton} />
              <div className={styles.RatingSkeleton} />
            </div>

            {/* Install button skeleton */}
            <div className={styles.InstallBtnContainer}>
              <div className={styles.ButtonSkeleton} />
            </div>
          </div>
        </div>

        {/* Description skeleton */}
        <div className={styles.DescriptionSkeleton} />
        
        {/* Image skeleton */}
        <div className={styles.ImageContainer}>
          <div className={styles.ImageSkeleton} />
        </div>
      </Card>
    </div>
  );
};

export default HubServiceCardSkeleton;