import { API } from '@aws-amplify/api';
import {
	GetPublicationsResponse,
	SubmitPublicationRatingResponse
} from '@kemu-io/kemu-types';
import { ValidPlatformArch } from '@kemu-io/hs-types';
import globals from '@common/globals';

const API_NAME = globals.MARKETPLACE_API_NAME;
// Forces to return the entire Axios response object instead of only response.data
const returnAxiosResponse = { response: true };

/**
 * Gets a list of publications
 * @param from the index of the first publication to return
 * @param total the number of publications to return
 * @param query an optional query string to filter the publications
 * @returns a list of publications
 */
export const getPublications = async (config: {
	from: number,
	total: number,
	platform: ValidPlatformArch,
	query?: string,
}): Promise<GetPublicationsResponse> => {
	const { from, total, platform, query } = config;
	const url = `/v1/publications/${platform}?from=${from}&total=${total}&q=${query}`;
	const recipe = await API.get(API_NAME, url, returnAxiosResponse);
	return recipe.data;
};

/**
 * Rates a publication
 * @param config 
 * @returns the updated publication stats
 */
export const ratePublication = async (config: {
	publicationId: string,
	rating: number,
	comment?: string,
}): Promise<SubmitPublicationRatingResponse> => {
	const { publicationId, rating, comment } = config;
	const response = await API.post(API_NAME, `/v1/publications/${publicationId}/rating?score=${rating}&comment=${comment || ''}`, returnAxiosResponse);
	const ratingResponse = response.data as SubmitPublicationRatingResponse;
	return ratingResponse;
};

// const getUploadUrl = async (recipeId: string, fileName: string): Promise<AssetsUploadResponse> => {
// 	const recipe = await API.get(API_NAME, `/marketplace/recipe/${recipeId}/upload?file=${fileName}`, returnAxiosResponse);
// 	return recipe.data;
// };

// /**
//  * Uploads a recipe to S3
//  * @param presignedUrl a pre-signed URL for the upload
//  * @param onProgressCb a method to call with the progress events of the upload
//  */
// // eslint-disable-next-line @typescript-eslint/no-explicit-any
// const uploadAsset = async (
// 	presignedUrl: string,
// 	contents: ArrayBuffer,
// 	onProgressCb?: (evt: TransferProgressEvent) => void
// ): Promise<any> => {
// 	const results = await axios.put(`${presignedUrl}`, contents, {
// 		// NOTE: this MUST match the content type set by the service when creating the pre-signed url
// 		headers: { 'Content-Type': 'application/octet-stream' },
// 		onUploadProgress: (evt) => {
// 			if (onProgressCb) {
// 				const total = evt.total || 1;
// 				const percentage = Math.round((evt.loaded / total) * 100);
// 				onProgressCb({
// 					loaded: evt.loaded,
// 					total,
// 					percentage,
// 				});
// 			}
// 		}
// 	});

// 	return results.data;
// };

// const deleteAsset = async (assetId: string, fileName: string, publicationVersion: number): Promise<void> => {
// 	const response = await API.del(API_NAME, `/marketplace/recipe/${assetId}/${publicationVersion}/${fileName}`, returnAxiosResponse);
// 	logger.log('File delete response: ', response);
// };

// const publishRecipe = async (recipeId: string, publication: PublishEntityRequestBody): Promise<PublicationEntity> => {
// 	const response = await API.post(API_NAME, `/marketplace/recipe/publish/${recipeId}`, { ...returnAxiosResponse, body: publication });
// 	logger.log('Publication response: ', response);
// 	const publishedRecipe = response.data as PublicationEntity;
// 	return publishedRecipe;
// };

// /**
//  * Publishes a new version of a recipe
//  */
// const publishRecipeVersion = async (existingPublicationId: string, publication: PublishEntityRequestBody): Promise<PublicationEntity> => {
// 	// POST /marketplace/{entityType}/republish/{publicationId}
// 	const response = await API.post(API_NAME, `/marketplace/recipe/republish/${existingPublicationId}`, { ...returnAxiosResponse, body: publication });
// 	logger.log('New Publication response: ', response);
// 	const publishedRecipe = response.data as PublicationEntity;
// 	return publishedRecipe;
// };


// const getPublicationDetails = async (publicationType: PublicationType, recipeId: string): Promise<GetPublicationDetailsResponse> => {
// 	const recipe = await API.get(API_NAME, `/marketplace/${publicationType}/${recipeId}`, returnAxiosResponse);
// 	return recipe.data;
// };


// const getPublicRecipes = async (): Promise<RatedPublicationEntity[]> => {
// 	const recipe = await API.get(API_NAME, `/marketplace/recipe`, returnAxiosResponse);
// 	return recipe.data;
// };

// const ratePublication = async (publicationId: string, version: number, rating: number): Promise<RatePublicationResponse> => {
// 	const response = await API.post(API_NAME, `/marketplace/rate/${publicationId}`, { ...returnAxiosResponse, body: { rating, version } });
// 	const ratingResponse = response.data as RatePublicationResponse;
// 	return ratingResponse;
// };

// /**
//  * Changes the state of a publication.
//  * @param publicationId 
//  * @param state 
//  * @param message 
//  */
// const submitReviewDecision = async (publicationId: string, state: PublicationState, message: string): Promise<SetReviewDecisionResponse> => {
// 	const response = await API.post(API_NAME, `/marketplace/review/${publicationId}`, { ...returnAxiosResponse, body: { state, message } });
// 	return response.data as SetReviewDecisionResponse;
// };

// /**
//  * Marks an existing publication as 'unpublished'. Only the publication owner has access to this API.
//  * @param publicationId 
//  * @returns the updated publication.
//  */
// const unpublishEntity = async (publicationId: string): Promise<PublicationEntity> => {
// 	const response = await API.post(API_NAME, `/marketplace/unpublish/${publicationId}`, { ...returnAxiosResponse });
// 	return response.data as PublicationEntity;
// };

// /**
//  * Imports a published recipe or tutorial into the user's account
//  * @param publicationId 
//  */
// const addToUserLibrary = async (publicationId: string): Promise<ImportPublicationResponse> => {
// 	const response = await API.post(API_NAME, `/marketplace/${publicationId}/marketplace/import`, returnAxiosResponse);
// 	const importResponse = response.data as ImportPublicationResponse;
// 	return importResponse;
// };

// export {
// 	getUploadUrl,
// 	deleteAsset,
// 	publishRecipe,
// 	getPublicationDetails,
// 	getPublicRecipes,
// 	ratePublication,
// 	uploadAsset,
// 	addToUserLibrary,
// 	publishRecipeVersion,
// 	submitReviewDecision,
// 	unpublishEntity
// };
