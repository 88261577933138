import { useCallback } from 'react';
import { MarketplaceRecordWithRatings } from '@kemu-io/kemu-types';
import { ValidPlatformArch } from '@kemu-io/hs-types';
import { getPublications } from '@src/api/marketplace/marketplaceApi';

interface FetchError {
  message: string;
  status?: number;
}

const useMarketplaceFetch = () => {
  const fetchData = useCallback(async (config: {
    from: number,
    total: number,
    query?: string,
    platform: ValidPlatformArch,
  }): Promise<MarketplaceRecordWithRatings[]> => {

    const { from, total, query, platform } = config;
    try {
      // const queryParams = new URLSearchParams({
      //   from: from.toString(),
      //   total: total.toString(),
      //   ...(query && { query })
      // });

      // const response = await fetch(`${MARKETPLACE_API_URL}?${queryParams}`);

      // if (!response.ok) {
      //   throw new Error(`HTTP error! status: ${response.status}`);
      // }

      // const data = await response.json();
      const response = await getPublications({ from, total, query, platform });
      return response.publications;
    } catch (error) {
      const fetchError: FetchError = {
        message: error instanceof Error ? error.message : 'An error occurred while fetching data',
        status: error instanceof Response ? error.status : undefined
      };
      throw fetchError;
    }
  }, []);

  return fetchData;
};

export default useMarketplaceFetch;
