import React, { ReactElement } from 'react';
import classNames from 'classnames';
import Modal from 'antd/lib/modal';
import Space from 'antd/lib/space';
import CardWithBorder from '../cardWithBorder/cardWithBorder';
import StyledButton, { ButtonColors } from '../form-control/styledButton/styledButton';
import Spinner from '../spinner/spinner';
import styles from './roundedModal.module.css';

interface Props {
	onOk?: ()=> void;
	onCancel?: ()=> void;
	onAfterClosed?: ()=> void;
	title: string | ReactElement;
	children: React.ReactNode;
	visible: boolean;
	customFooter?: React.ReactNode;
	okBtnLabel?: string | React.ReactNode;
	cancelBtnLabel?: string | React.ReactNode;
	width?: number | string;
	closeOnMaskClick?: boolean;
	/** Modal inner card's class name */
	className?: string;
	/** class name of the card's body. */
	bodyClassName?: string;
	footerClassName?: string;
	transparentFooter?: boolean;
	okButtonColor?: ButtonColors;
	cancelButtonColor?: ButtonColors;
	disableOkButton?: boolean;
	disableCancelButton?: boolean;
	noFooter?: boolean;
	centered?: boolean;
	closable?: boolean;
	noMask?: boolean;
	loading?: boolean;
	loadingCancelBtn?: boolean;
	loadingOkBtn?: boolean;
	transitionName?: string;
	maskTransitionName?: string;
}

const RoundedModal = (props: Props): React.JSX.Element => {
	return (
		<Modal
			transitionName={props.transitionName}
			maskClosable={props.closeOnMaskClick}
			maskTransitionName={props.maskTransitionName}
			width={props.width}
			closable={props.closable}
			open={props.visible}
			styles={{
				body: { padding: 'unset' },
			}}
			mask={!props.noMask}
			onOk={props.onOk}
			onCancel={props.onCancel}
			footer={null}
			centered={props.centered}
			// okButtonProps={{ hidden: true }}
			destroyOnClose={true}
			className={styles.ModalContainer}
			afterClose={props.onAfterClosed}
		>
			<CardWithBorder
				title={props.title}
				borderWidth={3}
				className={classNames(styles.CardShadow, props.className)}
				bodyClassName={props.bodyClassName}
			>
				<Spinner loading={!!props.loading}>
					<div className={styles.ModalContainer}>
						{props.children}

						{!props.noFooter && (
							<div className={classNames(styles.Footer, props.footerClassName)}>
								{props.customFooter ? (
									props.customFooter
								) : (
									props.cancelBtnLabel ? (
										<Space size={20}>
											{/* Cancel */}
											<StyledButton
												loading={props.loadingCancelBtn}
												disabled={!!props.disableCancelButton}
												color={props.cancelButtonColor || 'light'}
												title={props.cancelBtnLabel}
												onClick={props.onCancel}
											/>
											{/* Ok */}
											<StyledButton
												loading={props.loadingOkBtn}
												disabled={!!props.disableOkButton}
												color={props.cancelButtonColor || 'primary'}
												title={props.okBtnLabel}
												onClick={props.onOk}
											/>
										</Space>
									) : (
										// OK button only
										<StyledButton
											loading={props.loadingOkBtn}
											color={props.okButtonColor || 'light'}
											disabled={!!props.disableOkButton}
											title={props.okBtnLabel}
											onClick={props.onOk}
										/>
									)
								)}
							</div>
						)}
					</div>
				</Spinner>
			</CardWithBorder>
		</Modal>
	);
};

export default RoundedModal;
