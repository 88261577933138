import React, { useCallback, useEffect, useState } from 'react';
import { MultiSelectConfig } from '@kemu-io/kemu-core/types';
import { Col,  Row } from 'antd';
import { useDebouncedCallback } from 'use-debounce';
import styles from './MultiSelectFieldConfig.module.css';
import FormGroup from '@components/form-control/formGroup/formGroup';
import StandardModal from '@components/modal/standardModal';
import StyledInput from '@components/form-control/styledInput/styledInput';
import StyledTextArea from '@components/form-control/styledTextArea/styledTextArea';
import useTranslation from '@hooks/useTranslation';

type Props = {
  open: boolean;
  fieldIndex: number;
  currentConfig: MultiSelectConfig;
  onCloseModal: (fieldIndex: number, newConfig: MultiSelectConfig | null) => void;
}

const cleanOptions = (options: string[]) => {
  const value = options;
  const split = value.filter((option) => option.trim().length > 0).map((option) => option.trim().replace(/,/g, ''));
  return split;
};

const MultiSelectFieldConfig = (props: Props) => {
  const t = useTranslation();
  const { currentConfig, onCloseModal, fieldIndex, open } = props;
  const [config, setConfig] = useState<MultiSelectConfig>(currentConfig);

  const handleDebouncedDefaultChange = useDebouncedCallback(() => {
    setConfig((prevConfig) => {
      const clean = cleanOptions(prevConfig?.defaultValue || []);
      return {
        ...prevConfig,
        defaultValue: clean,
      };
    });
  }, 1000);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleOptionsChange = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setConfig((prevConfig) => {
      return {
        ...prevConfig,
        options: event.target.value.split('\n')
      };
    });
  }, []);

  const handleKeyDown = useCallback((event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter') {
      event.currentTarget.value = event.currentTarget.value.split('\n')
        .map((option) => option.trim())
        .join('\n');
    }
  }, []);

  const handleDefaultValueChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setConfig((prevConfig) => {
      return {
        ...prevConfig,
        options: prevConfig?.options || [],
        defaultValue: event.target.value.split(', '),
      };
    });

    handleDebouncedDefaultChange();
  }, [handleDebouncedDefaultChange]);

  const handleSaveSettings = useCallback(() => {
    const cleanConfig: MultiSelectConfig = {
      ...config,
      defaultValue: cleanOptions(config?.defaultValue || []),
    };

    onCloseModal(fieldIndex, cleanConfig);
  }, [config, fieldIndex, onCloseModal]);

  const handleCloseModal = useCallback(() => {
    onCloseModal(fieldIndex, null);
  }, [fieldIndex, onCloseModal]);

  useEffect(() => {
    setConfig(currentConfig);
  }, [currentConfig]);


  return (
    <StandardModal
      title={t('CustomWidget.Settings.FieldConfiguration', '{fieldName} Field Configuration', {
        fieldName: 'MultiSelect',
      })}
      centered
      visible={open}
      width={330}
      onOk={handleSaveSettings}
      onCancel={handleCloseModal}
      okBtnLabel={t('CommonWords.Save', 'Save')}
      cancelBtnLabel={t('CommonWords.Cancel', 'Cancel')}
    >
      <Row>
        <Col span={24}>
          <FormGroup column={true}>
            <label>
              {t('CommonWords.DefaultValue', 'Default value')} ({String(t('CommonWords.Optional', 'Optional')).toLowerCase()})
            </label>
            <p>
              {t('CustomWidget.Settings.MultiSelect.Description')}
            </p>
            <StyledInput
              value={config?.defaultValue?.join(', ')}
              onChange={handleDefaultValueChange}
            />
          </FormGroup>
          <FormGroup column={true}>
          <label>{t('CommonWords.Options', 'Options')}</label>
            <StyledTextArea
              onKeyDown={handleKeyDown}
              preventResize={true}
              className={styles.TextArea}
              value={config?.options?.join('\n')}
              onChange={handleOptionsChange}
            />
          </FormGroup>
        </Col>
      </Row>
    </StandardModal>
  );
};

export default MultiSelectFieldConfig;


