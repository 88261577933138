import React, { useCallback, useEffect, useState } from 'react';
import AutoComplete from 'antd/lib/auto-complete';
import styles from './InputWithAutoComplete.module.css';

interface Props {
	className?: string;
	containerClassName?: string;
	placeholder?: string;
	value?: string | number;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onChange?: (value: string, meta?: any) => void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onChangeMeta?: any;
	options?: string[]
}

const InputWithAutoComplete = (props: Props): React.JSX.Element => {
	const [properties, setProperties] = useState((props.options || []).map(v => ({ value: v })));
	const { onChange, onChangeMeta } = props;

	const handleValueChange = useCallback((text: string) => {
		onChange?.(text, onChangeMeta);
	}, [onChange, onChangeMeta]);

	useEffect(() => {
		if (props.options) {
			const newProperties = (props.options || []).map(v => ({ value: v }));
			setProperties(newProperties);
		}
	}, [props.options, props.options?.length]);

	return (
		<div className={`gate-input ${props.containerClassName}`}>
			<AutoComplete
				className={styles.Input}
				placeholder={props.placeholder}
				value={String(props.value)}
				onChange={handleValueChange}
				options={properties}
			/>
		</div>
	);
};

export default InputWithAutoComplete;
