import React, { useCallback, useEffect, useState } from 'react';
import { Button, Tooltip, Space, message, Menu, Spin } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import Icon, {
	DeleteOutlined,
	ShareAltOutlined,
	FolderOpenOutlined,
	ExclamationCircleOutlined,
	EditOutlined,
	CopyOutlined,
	LoadingOutlined,
	InfoCircleOutlined,
} from '@ant-design/icons';

import { formatDistanceToNow, parseISO } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { PublicationState, SharedWithUser } from '@kemu-io/kemu-types';
import { ReactSVG } from 'react-svg';
import { removeRecipe, setAsyncOperationDetails, asyncOperationState } from '../../features/interface/interfaceSlice';
import { removeRecipeFromList, unpublishRecipeState } from '../layout/marketplace/marketplaceSlice';
import { AsyncRequestStatus, AsyncState, RecipeResponse } from '../../types/core_t';
import { selectCurrentRecipe } from '../../features/Workspace/workspaceSlice';
import RecipeShare from '../recipeShare/recipeShare';
import useAlert from '../alert/useAlert';
import VerticalDots from '../verticalDots/verticalDots';
import RenameModal from '../renameModal/renameModal';
import * as recipeApi from '../../api/recipe/recipeApi';
import CardRibbon from '../marketplace/CardRibbon/CardRibbon';
import useTranslation from '../../common/hooks/useTranslation';
import useMarkedText from '../../common/hooks/useMarkedText';
import { USER_CHAT_PROPERTIES } from '../../common/constants';
import getFreshChatWidget from '../../app/recipe/freshChatWidget';
import { ReactComponent as TutorialsIcon } from '../layout/marketplace/MarketplaceMenu/tutorials.svg';
import routes from '../../common/routes/index';
import { downloadRecipeAction } from '../../features/interface/reducers/downloadRecipeReducer';
import styles from './RecipeCard.module.css';
import { ReactComponent as UnpublishRecipeIcon } from './unpublishRecipeIcon.svg';

export type RecipeCardDetails = {
	shareList?: SharedWithUser[],
	publicShareId?: string | null
}

interface Props {
	onForceReload?: ()=> void;
	onShowPublisher: (recipe: RecipeResponse)=> void;
	selected?: boolean;
	recipe: RecipeResponse;
}

const statesWithRibbon: PublicationState[] = ['pending', 'published', 'rejected'];

function RecipeCard (props: Props): React.JSX.Element | null {
	const Alert = useAlert();
	const t = useTranslation();
	const dispatch = useDispatch();
	const parseHtml = useMarkedText();
	const [renaming, setRenaming] = useState(false);
	// const [linkingTutorial, setLinkingTutorial] = useState(false);
	const chatWidget = getFreshChatWidget();

	// Create slice for individual operations (rename, delete)
	const [asyncOperation, setAsyncOperation] = useState<AsyncState>({
		status: AsyncRequestStatus.idle
	});

	const { onForceReload, onShowPublisher } = props;
	const { id: recipeID, name: recipeName, sharedWith, publicSharedId } = props.recipe;
	const recipeInfo = useSelector(selectCurrentRecipe);
	const [cardDetails, setCardDetails] = useState(props.recipe);
	const [sharedWithList, setSharedWithList] = useState(sharedWith);
	const [publicUrl, setPublicUrl] = useState<string | null>(publicSharedId || null);
	const unpublishingState = useSelector(unpublishRecipeState);
	const asyncState = useSelector(asyncOperationState);
	const [shareDialogVisible, setShareDialogVisible] = useState(false);

	const asyncOperationOwner = asyncState?.entityId === recipeID;
	const deletionInProgress = asyncState?.status === AsyncRequestStatus.loading && asyncOperationOwner;
	const deletionSuccessful = asyncState?.status === AsyncRequestStatus.completed && asyncOperationOwner;
	const deletionErrorReason = asyncState?.status === AsyncRequestStatus.error ? asyncState.error?.message || 'Unknown': null;

	const navigate = useNavigate();
	const date = cardDetails.updatedAt ? parseISO(cardDetails.updatedAt) : new Date();
	const createdSince = formatDistanceToNow(date) + ' ago';
	const protocolVersion = Number(props.recipe.protocolVersion || 0);
	const tooOld = protocolVersion < 1.6;

	const onUserListUpdated = (details: RecipeCardDetails) => {
		if (details.shareList) {
			setSharedWithList(details.shareList);
		}

		if (details.publicShareId !== undefined) { setPublicUrl(details.publicShareId); }
	};

	const handlePublishRecipe = useCallback(() => {
		onShowPublisher(props.recipe);
	}, [props.recipe, onShowPublisher]);

	const onDelete = () => {
		dispatch(setAsyncOperationDetails({
			entityId: recipeID,
			action: 'delete'
		}));

		dispatch(removeRecipe({
			recipeId: recipeID,
			onSuccess: onForceReload,
		}));
	};

	const handleDeleteConfirm = () => {
		if (props.recipe?.publication?.state === 'pending' || props.recipe?.publication?.state === 'published') {
			return Alert.info({
				title: t('RecipeDeletionDialog.PublicationExists.Title', 'Cannot Delete Recipe'),
				iconColor: 'info',
				content: t('RecipeDeletionDialog.PublicationExists.Content', 'You need to \'Unpublish\' this recipe first.'),
				okText: t('RecipeDeletionDialog.PublicationExists.BtnClose', 'Close'),
				okButtonColor: 'primary',
			});
		}

		Alert.confirm({
			title: t('RecipeDeletionDialog.Title', 'Delete Recipe'),
			iconColor: 'warning',
			content: t('RecipeDeletionDialog.Content', 'Are you sure you want to delete "{name}"?',  { name: recipeName } ),
			okText: t('RecipeDeletionDialog.BtnOk', 'Delete'),
			okButtonColor: 'danger',
			cancelText: t('RecipeDeletionDialog.BtnCancel', 'Cancel'),
			onOk: onDelete
		});
	};

	const proceedIfSafe = (next: ()=> void) => {
		if (recipeInfo.needsSaving) {
			Alert.error({
				title: t('RecipeUnsavedWarning.Title', 'Merge Recipe'),
				icon: <ExclamationCircleOutlined />,
				content: t('RecipeUnsavedWarning.Content', 'You have unsaved changes in "{name}". If you proceed your changes will be lost.',  { name: recipeInfo.name } ),
				okText: t('RecipeUnsavedWarning.BtnOk', 'Proceed'),
				okButtonColor: 'warning',
				cancelText: t('RecipeUnsavedWarning.BtnCancel', 'Cancel'),
				onOk: next
			});
		} else {
			next();
		}
	};

	const handleUnpublishRecipe = () => {
		const proceed = () => {
			if (cardDetails.publication) {
				// TODO: Implement
			}
		};

		if (cardDetails.publication) {
			Alert.confirm({
				iconColor: 'warning',
				title: t('RecipeUnpublishDialog.Title', 'Unpublish Recipe?'),
				content: t('RecipeUnpublishDialog.Content', 'Unpublishing this recipe will remove it from the Marketplace.'),
				okText: t('RecipeUnpublishDialog.BtnOk', 'Proceed'),
				okButtonColor: 'warning',
				cancelText: t('RecipeUnpublishDialog.BtnCancel', 'Cancel'),
				onOk: proceed
			});
		}
	};


	const handleCloning = useCallback(async () => {
		try {
			setAsyncOperation({ status: AsyncRequestStatus.loading });
			const createdRecipe = await recipeApi.cloneRecipe(recipeID, `${recipeName} copy`);
			console.log('Cloned recipe: ', createdRecipe);
			onForceReload && onForceReload();
		} catch (e) {
			message.error({ content: `Error cloning recipe: ${e.message || ''}`, duration: 3 });
			setAsyncOperation({ status: AsyncRequestStatus.completed, error: e });
		}
	}, [recipeID, recipeName, onForceReload]);


	// TODO: Move all these operations to redux reducers
	/** Rename a recipe */
	const handleRenaming = async (name: string) => {
		setRenaming(false);
		try {
			setAsyncOperation({ status: AsyncRequestStatus.loading });
			const updatedDetails = await recipeApi.patchRecipe(props.recipe.id, { name });
			setAsyncOperation({ status: AsyncRequestStatus.completed });
			setCardDetails(details => ({ ...details, name: updatedDetails.name, updatedAt: updatedDetails.updatedAt }));
			onForceReload && onForceReload();
		} catch (e) {
			message.error({ content: `Error renaming recipe: ${e.message || ''}`, duration: 3 });
			setAsyncOperation({ status: AsyncRequestStatus.error, error: e });
		}
	};

	/** Open recipe */
	const handleOpen = () => {
		proceedIfSafe(() => {
			if (chatWidget.initialized) {
				chatWidget.setUserProperty({
					[USER_CHAT_PROPERTIES.LastOpenedRecipeId]: cardDetails.id,
					[USER_CHAT_PROPERTIES.LastOpenedRecipeName]: cardDetails.name,
				});
			}

			dispatch(downloadRecipeAction({ recipeId: recipeID, navigate, alert: Alert }));
		});
	};

	const showRejectionInfoDialog = () => {
		const getCleanComment = () => {
			if (props.recipe.publication?.audit) {
				return parseHtml(props.recipe.publication.audit.message);
			}

			return '';
		};

		if (props.recipe.publication?.state === 'rejected' && props.recipe.publication?.audit) {
			Alert.info({
				title: t('RecipeRevision.Rejected.Title', 'Publication Rejected'),
				icon: <ExclamationCircleOutlined />,
				content: <>
					<span dangerouslySetInnerHTML={{ __html: getCleanComment() }} />
				</>,
				okText: t('RecipeRevision.Rejected.CloseBtn', 'Close'),
			});
		}
	};

	const menu = (
		<Menu className={styles.DropdownMenu}>
			<Menu.Item key="1" icon={<EditOutlined />} onClick={() => setRenaming(true)} data-kemu-meta="rename">
				{t('Marketplace.MyRecipes.Card.Menu.Rename', 'Rename')}
			</Menu.Item>

			<Menu.Item key="2" icon={<CopyOutlined />} onClick={handleCloning} data-kemu-meta="duplicate">
				{t('Marketplace.MyRecipes.Card.Menu.Duplicate', 'Duplicate')}
			</Menu.Item>

			{/* {cardDetails.linkedTutorial ? (
				<Menu.Item key="3" icon={<Icon component={LinkTutorialIcon} />} onClick={handleRemoveTutorialLink} data-kemu-meta="unlink-tutorial">
					{t('Marketplace.MyRecipes.Card.Menu.UnlinkTutorial', 'Unlink Tutorial')}
				</Menu.Item>
			) : (
				<Menu.Item key="3" icon={<Icon component={LinkTutorialIcon} />} onClick={() => setLinkingTutorial(true)} data-kemu-meta="link-tutorial">
					{t('Marketplace.MyRecipes.Card.Menu.LinkTutorial', 'Link Tutorial')}
				</Menu.Item>
			)} */}

			{cardDetails.publication && statesWithRibbon.includes(cardDetails.publication.state) && (
				<Menu.Item key="unpublish" icon={<Icon component={UnpublishRecipeIcon} />} onClick={handleUnpublishRecipe} data-kemu-meta="unpublish-recipe">
					{t('Marketplace.MyRecipes.Card.Menu.Unpublish', 'Unpublish Recipe')}
				</Menu.Item>
			)}
			{/* <Menu.Divider /> */}
		</Menu>
	);

	const closeShareDialog = () => {
		setShareDialogVisible(false);
	};

	useEffect(() => {
		if (deletionSuccessful) {
			console.log('Removing from list: ', recipeID, deletionSuccessful);
			dispatch(removeRecipeFromList(recipeID));
		}
	}, [deletionSuccessful, recipeID, dispatch]);


	useEffect(() => {
		// TODO: Refactor this, TERRIBLE!
		const msgKey = 'deletionIndicator';
		if (asyncOperationOwner) {
			if (deletionInProgress) { message.loading({ content: t('DeleteRecipeIndicator.Progress', 'Deleting Recipe...'), duration: 0, key: msgKey }); }
			if (deletionSuccessful) { message.success({ content: t('DeleteRecipeIndicator.Success', 'Recipe Deleted!'), duration: 3, key: msgKey }); }
			if (deletionErrorReason) {
				message.error({
					content: t('DeleteRecipeIndicator.Failure', 'Failed to delete recipe') + `: ${deletionErrorReason}`,
					duration: 3,
					key: msgKey
				});
			}
		}
	}, [deletionInProgress, deletionSuccessful, asyncOperationOwner, recipeID, t, deletionErrorReason]);

	const PublishedRibbon = (): React.JSX.Element => {
		const intlMsgByState: Record<string, string> = {
			published: 'Marketplace.MyRecipes.CardRibbon.Published',
			pending: 'Marketplace.MyRecipes.CardRibbon.Pending',
			rejected: 'Marketplace.MyRecipes.CardRibbon.Rejected',
		};

		let ribbonMsg = t(intlMsgByState[props.recipe.publication?.state || ''], '');
		if (props.recipe.publication && props.recipe.publication.version > 1) {
			ribbonMsg = `${ribbonMsg} v${props.recipe.publication?.version}`;
		}

		return (
			<CardRibbon
				state={props.recipe.publication?.state}
				text={
					<>
						<Link to={routes.marketplace.getPublicationDetailsRoute('recipe', props.recipe.publication?.id || '')}>
							{ribbonMsg}
						</Link>
						{props.recipe.publication?.state === 'rejected' && (
							<Tooltip title={t('Marketplace.MyRecipes.Card.RejectedMoreInfoBtn', 'More info')}>
								<span className={styles.RejectedInfoBtn} onClick={showRejectionInfoDialog}>
									<InfoCircleOutlined />
								</span>
							</Tooltip>
						)}
					</>
				}
			>
				<CardHeader />
			</CardRibbon>
		);

	};


	const getDynamicSVG = (url: string) => {
		return () => <ReactSVG src={url} className="dynamic"/>;
	};

	const NewRibbon = (): React.JSX.Element => {
		return (
			<CardRibbon color="#1fc314" text={t('Marketplace.MyRecipes.CardRibbon.New', 'New')}>
				<CardHeader />
			</CardRibbon>
		);
	};

	const CardHeader = (): React.JSX.Element => {
		return (
			<div className={styles.Header}>
				<div className={styles.HeaderIcons}>
					{props.recipe.blocks.map((block, i) => (
						<span key={`${block.id}_${i}`}>
							{/* Only show up to 5 icons */}
							{i > 3 ? null : (
								<Icon component={getDynamicSVG(`${routes.recipe.getStaticAssetsRoute(block.type, block.version)}/icon.svg`)} />
							)}
						</span>
					))}
				</div>
		</div>
		);
	};


	return (
		<>
			<article className={`${styles.RecipeBody} ${props.selected ? 'selected' : ''}`} data-kemu-meta="recipe-card">
				<Spin
					indicator={<LoadingOutlined />}
					spinning={
						asyncOperation.status === AsyncRequestStatus.loading ||
						(unpublishingState.publicationId === props.recipe.publication?.id && unpublishingState.asyncState.status === AsyncRequestStatus.loading)
					}
				>

					{props.recipe.publication && statesWithRibbon.includes(props.recipe.publication.state) ? (
						<PublishedRibbon />
					): props.selected ? (
						<NewRibbon />
					): (
						<CardHeader />
					)}

					<div className={styles.Details}>
						<div className={styles.Title}>
							<h2>
								{cardDetails.linkedTutorial && (
									<span className={styles.TutorialIcon}>
										<Link to={routes.tutorial.getTutorialRoute(cardDetails.linkedTutorial, 0)}>
											<Tooltip title={t('Marketplace.MyRecipes.Card.LinkedTutorialTooltip', 'Click to open linked tutorial' )}>
												<Icon component={TutorialsIcon} />
											</Tooltip>
										</Link>
									</span>
								)}

								{cardDetails.name}
							</h2>
						</div>
						<div className={styles.Category}>
							<div className={styles.UserName}>{cardDetails.author?.name || 'Unknown'}</div>
							<div className={styles.TimeContainer}>
								{/* <div className={styles.Bullet} /> */}
								<div className={styles.DateInfo}>
									{createdSince}
								</div>
							</div>

						</div>
						<Space size={10}>
							<Tooltip
								destroyTooltipOnHide={true}
								title={ tooOld ? t('Marketplace.MyRecipes.Card.TooOld', 'Recipe not compatible') : t('Marketplace.MyRecipes.Card.OpenBtn', 'Open')}
							>
								<Button
									data-kemu-meta="open-recipe"
									disabled={deletionInProgress || tooOld}
									onClick={handleOpen}
									type="primary"
									className={styles.CircleButton}
									shape="circle" icon={<FolderOpenOutlined />}
								/>
							</Tooltip>

							<Tooltip destroyTooltipOnHide={true} title={<FormattedMessage id="Marketplace.MyRecipes.Card.ShareBtn" defaultMessage="Share recipe"/>}>
								<Button data-kemu-meta="share-recipe" disabled={deletionInProgress} onClick={() => setShareDialogVisible(true)} type="primary" className={styles.CircleButton} shape="circle" icon={<ShareAltOutlined />} />
							</Tooltip>

							{/* Recipe publishing disabled for now */}
							{/* <Tooltip
								destroyTooltipOnHide={true}
								title={
									props.recipe.publication ? (
										<FormattedMessage id="Marketplace.MyRecipes.Card.PublishVersion" defaultMessage="Publish new version"/>
									) : (
										<FormattedMessage id="Marketplace.MyRecipes.Card.Publish" defaultMessage="Publish recipe"/>
									)
								}
							>
								<Button
									data-kemu-meta="publish-recipe"
									disabled={deletionInProgress}
									onClick={handlePublishRecipe}
									type="primary"
									className={styles.CircleButton}
									shape="circle"
									icon={<Icon component={PublishIcon} className={styles.CustomSvg} />}
								/>
							</Tooltip> */}

							{/* <Tooltip destroyTooltipOnHide={true} title={<FormattedMessage id="Marketplace.MyRecipes.Card.MergeBtn" defaultMessage="Merge into current recipe"/>}>
								<Button disabled={deletionInProgress} onClick={handleCombine} type="primary" className={styles.CircleButton} shape="circle" icon={<Icon component={MergeIcon} className={styles.CustomSvg} />} />
							</Tooltip> */}
							{/* <Tooltip destroyTooltipOnHide={true} title={<FormattedMessage id="Marketplace.MyRecipes.Card.DownloadBtn" defaultMessage="Download"/>}>
								<Button disabled={deletionInProgress} type="primary" className={styles.CircleButton} shape="circle" icon={<DownloadOutlined />} />
							</Tooltip> */}
							<Tooltip destroyTooltipOnHide={true} title={<FormattedMessage id="Marketplace.MyRecipes.Card.DeleteBtn" defaultMessage="Delete"/>}>
								<Button data-kemu-meta="delete-recipe" disabled={deletionInProgress} onClick={handleDeleteConfirm} type="primary" danger className={styles.CircleButton} shape="circle" icon={<DeleteOutlined />} />
							</Tooltip>
						</Space>
					</div>

					<VerticalDots menu={menu} />
				</Spin>
			</article>

			{ renaming && (
				<RenameModal
					onSubmit={handleRenaming}
					onCancel={() => setRenaming(false)}
					currentName={cardDetails.name}
				/>
			)}

			<RecipeShare onDetailsUpdated={onUserListUpdated} recipeId={cardDetails.id} shareList={sharedWithList} publicShareId={publicUrl} visible={shareDialogVisible} onClose={closeShareDialog}/>
		</>
	);
}

	export default RecipeCard;

