import { useCallback, useMemo, useState } from "react";
import { selectSignedUserProfile } from "@src/app/reducers/user/userSlice";
import { useSelector } from "react-redux";
import useHubDefaultService, { DefaultServices } from "@hooks/useHubDefaultService";
import { InstallServiceConfig } from "@src/types/hubService";
import connectionManager from "@src/app/kemuHub/connectionManager";
import useGlobalNotification from "@hooks/useGlobalNotification";
import useTranslation from "@hooks/useTranslation.ts";
import { EnvironmentInfo } from "@kemu-io/hs-types";

/**
 * @returns a function that can be used to install Hub Services from the marketplace.
 */
const useInstallMarketplaceItem = () => {
  const { apiKey } = useSelector(selectSignedUserProfile);
  const findDefaultHubService = useHubDefaultService();
  const hubConnector = connectionManager.getConnector();
  const [installing, setInstalling] = useState(false);
  const { showNotification } = useGlobalNotification();
  const t = useTranslation('CommonEvents');

  const installHubService = useCallback(async (config: {marketplaceItemId: string, title?: string}) => {  
    const { marketplaceItemId, title } = config;
    try {
      const serviceInstaller = findDefaultHubService(DefaultServices.ServiceInstaller.name);
      if (!serviceInstaller) {
        throw new Error(t('ServiceInstallerNotFound'));
      }
  
      if(!apiKey){
        throw new Error(t('ServiceInstallerNoApiKey'));
      }
  
      const request: InstallServiceConfig = {
        replaceCurrentVersion: false,
        keepPreviousVersion: false,
        apiKey,
        source: {
          marketplaceItem: { id: marketplaceItemId },
        },
      };
  
      setInstalling(true);
      const response = await hubConnector.callProcessorHandler(
        serviceInstaller.sessionId,
        {} as EnvironmentInfo,
        DefaultServices.ServiceInstaller.actions.installService,
        request,
        {
          // Wait for up to 15 minutes for the service installation to conclude
          timeout: 15 * 60 * 1000,
        }
      );
    
      if (response.error) {
        throw new Error(response.error);
      }
  
      if (response.success) {
        const hasMissingSecrets = response.launchedWithErrors?.errorCode === 'MISSING_REQUIRED_SECRETS';
        const standardMessage = t('ServiceInstalled.Message', 'Message', { service: title ?? marketplaceItemId }).toString();
        const message = hasMissingSecrets 
          ? t('ServiceInstalled.MissingSecrets', 'MissingSecrets', { service: title ?? marketplaceItemId }).toString() 
          : standardMessage;
  
        showNotification({
          type: 'success',
          title: t('ServiceInstalled.Title').toString(),
          message,
          duration: 5,
        });
  
        await connectionManager.refreshServicesCache();
      }
    } catch (e){
      console.error('Service install error:', e);
      showNotification({
        type: 'error',
        title: t('ServiceInstalledError.Title').toString(),
        message: e.message,
        duration: 5,
      });
    } finally {
      setInstalling(false);
    }
  }, [findDefaultHubService, apiKey, t, hubConnector, showNotification]);

  const result = useMemo(() => ({
    /** Installs a HubService by id from the marketplace */
    installHubService,
    /** whether a service is currently being installed */
    installingService: installing
  }), [installing, installHubService]);

  return result;
};

export default useInstallMarketplaceItem;
