import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import * as emotionReact from '@emotion/react';
import emotionCache from '@emotion/cache';
import {
	HubServiceState,
	DeepReadOnly,
	DeepReadOnlyArray,
	HSGlobalContext,
	WidgetUIComponent,
	WidgetUIInstance,
	GateInvocationEvent
} from '@kemu-io/kemu-core/types';
import { DEFAULT_THING_ID, DEFAULT_THING_VERSION } from '@kemu-io/kemu-core/common/constants';
import { useDispatch, useSelector } from 'react-redux';
import KemuCore from '@kemu-io/kemu-core';
import { DefaultVariantId } from '@kemu-io/kemu-core/widgets/hubService/index.js';
import { SerializableServiceInfo, BroadcastEvent, TargetOutput, RecipeType, KemuHubFunctions, ChooseDirectoryDialogArgs, Data } from '@kemu-io/hs-types';
import { createWidgetPortIdentifier } from '@kemu-io/kemu-core/common/utils';
import classNames from 'classnames';
import styles from './HsUIWrapper.module.css';
import { invokeNextGate } from '@src/app/recipe/utils';
import useWidgetState from '@hooks/useWidgetState';
import useReactiveWidgetState from '@hooks/useReactiveWidgetState';
import createLogger from '@common/logger';
import { useForceReload, useTranslation } from '@hooks/index';
import useKemuHubLink from '@hooks/useHubLink';
import useHubBroadcastEvent from '@hooks/useHubBroadcastEvent';
import useHubSetOutputsEvent from '@hooks/useHubSetOutputsEvent';
import { removeWidgetPortConnectionsAction, setWidgetDimensions } from '@src/features/LogicMapper/logicMapperSlice';
import useWidgetDimensions from '@hooks/useWidgetDimensions';
import { HubServiceCacheName, HubServiceCachePrefix } from '@common/constants';
import { selectVisibleGroup } from '@src/features/Workspace/workspaceSlice';

type Props = {
  recipeId: string;
  widgetId: string;
  uiContent: ArrayBuffer;
	hidden: boolean;
  // serviceName: string;
  // serviceVersion: string;
	contentsChecksum?: string;
	manifestChecksum?: string;
	/** 
	 * Overrides the manifest's color.
	 **/
	finalColor: string;
	serviceSessionId?: number;
	serviceOnline: boolean;
	disabled: boolean;
	manifest: SerializableServiceInfo;
  repaintPorts: () => void;
}

const logger = createLogger('HsUIWrapper');


const buildCacheFilePath = (fileName: string, serviceName: string, serviceVersion: string) => `${HubServiceCachePrefix}/${serviceName}/${serviceVersion}/${fileName}`;

const handleCacheFile = (getCache: () => Promise<Cache>, serviceName: string, serviceVersion: string) => async (filePath: string, data: any, headers?: Headers) => {
	const cache = await getCache();
	const cacheFilePath = buildCacheFilePath(filePath, serviceName, serviceVersion);
	await cache.put(cacheFilePath, new Response(data, {
		headers: headers || {
			'Content-Type': 'application/octet-stream',
		}
	}));
};

const handleGetCachedFile = (getCache: () => Promise<Cache>, serviceName: string, serviceVersion: string) => async (filePath: string) => {
	const cache = await getCache();
	const cacheFilePath = buildCacheFilePath(filePath, serviceName, serviceVersion);
	return cache.match(cacheFilePath);
};

const handleClearServiceCache = (getCache: () => Promise<Cache>, serviceName: string, serviceVersion: string) => async () => {
	const cache = await getCache();
	const keys = await cache.keys();
	const cacheFilePaths = keys.map((k) => k.url);
	const serviceCacheFilePaths = cacheFilePaths.filter((p) => p.startsWith(`${HubServiceCachePrefix}/${serviceName}/${serviceVersion}`));
	await Promise.all(serviceCacheFilePaths.map((p) => cache.delete(p)));
};

const handleRemoveCachedFile = (getCache: () => Promise<Cache>, serviceName: string, serviceVersion: string) => async (filePath: string) => {
	const cache = await getCache();
	const cacheFilePath = buildCacheFilePath(filePath, serviceName, serviceVersion);
	await cache.delete(cacheFilePath);
};

const HsUIWrapper = (props: Props) => {
  const {
		recipeId, widgetId, uiContent, contentsChecksum, manifestChecksum,
		repaintPorts, manifest, serviceSessionId, serviceOnline, disabled,
		finalColor, hidden,
	} = props;
  const [customUITarget, setCustomUITarget] = useState<HTMLElement | null>(null);
  const widgetUIRef = useRef<WidgetUIInstance | null>(null);
	const alreadyMountedRef = useRef(false);
	const servicesCacheRef = useRef<Cache | null>(null);
	const visibleGroup = useSelector(selectVisibleGroup);
  const { getState } = useWidgetState<HubServiceState>(recipeId, DEFAULT_THING_ID, widgetId);
  const [_, setWidgetState] = useReactiveWidgetState<HubServiceState>(recipeId, DEFAULT_THING_ID, widgetId);
	const reload = useForceReload();
	const dispatch = useDispatch();
	const { connector } = useKemuHubLink();
	const getDimensions = useWidgetDimensions(recipeId, widgetId);
	const cw = useTranslation('CommonWords');
	const {
		name: serviceName,
		version: serviceVersion,
		eventEmitter
	} = manifest;



	// 	// This could happen after invoking `handleParentEvent` if the child component
	// 	// does not implement the handleParentEvent method, in such cases, the wrapper 
	// 	// will automatically remove the event listener. Here we force a re-render 
	// 	// to force the removal of the `ParentEventHandler` component
	// 	// in order to improve performance.
	// 	if (widgetUIRef.current?.handleParentEvent === null) {
	// 		logger.log('Component does not implement handleParentEvent, removing event listener.');
	// 		reload();
	// 	}
	// }, [reload]);

	const handleParentEvent = useCallback(async (event: GateInvocationEvent) => {
		if (widgetUIRef.current?.handleParentEvent) {
			const data = event.data;
			await widgetUIRef.current.handleParentEvent({
				data: data as Data,
				targetPort: event.targetPort,
				sourceWidget: {
					id: event.sourceGate || '',
					port: event.targetPort,
				}
			});
		}


		// This could happen after invoking `handleParentEvent` if the child component
		// does not implement the handleParentEvent method, in such cases, the wrapper 
		// will automatically remove the event listener. Here we force a re-render 
		// to force the removal of the `ParentEventHandler` component
		// in order to improve performance.
		if (widgetUIRef.current?.handleParentEvent === null) {
			logger.log('Component does not implement handleParentEvent, removing event listener.');
			reload();
		}
	}, [reload]);

  const customWidgetContext = useMemo(() => {
		// Prevent passing binary data to the widget
		const { ...uiInstanceManifest } = manifest;

		const getCache = async () => {
			if (!servicesCacheRef.current) {
				servicesCacheRef.current = await caches.open(HubServiceCacheName);
			}

			return servicesCacheRef.current;
		};

		const context: HSGlobalContext<HubServiceState> = {
			defineDynamicPorts: async (config) => {
				const { inputs: newInputs, outputs: newOutputs, variantId } = config;

				return setWidgetState((c) => {
					const currentState = { ...c };
					// Find if any of the default inputs are missing from the new configuration

					// Services define their inputs/outputs using the same keys in the manifest file. When dynamic ports are set via `defineDynamicPorts`, 
					// only the missing ports are added to the state. This means that the existing default ports are not removed when the new configuration is set.
					// Service variants use a similar approach, where their default ports are defined in the manifest file variant[id].[inputs|outputs] and the dynamic ports 
					// are added to the state when `defineDynamicPorts` is called.

					const variantDefinition = c.service?.variants?.find((v) => v.id === variantId);

					const defaultInputs = variantDefinition ? variantDefinition.inputs : c.service?.inputs;
					const defaultOutputs = variantDefinition ? variantDefinition.outputs : c.service?.outputs;

					const missingInputs = (defaultInputs || []).filter((input) =>
						newInputs !== undefined &&
						(!newInputs?.some(o => o.name === input.name) || newInputs === null)
					);

					const missingOutputs = (defaultOutputs || []).filter((output) =>
						newOutputs !== undefined &&
						(!newOutputs?.some(o => o.name === output.name) || newOutputs === null)
					);

					// remove connections from any of the ports about to be removed.
					missingInputs.forEach((input) => {
						const portIdentifier = createWidgetPortIdentifier(widgetId, 'input', input.name);
						dispatch(removeWidgetPortConnectionsAction({
							portName: portIdentifier,
							recipeId,
							widgetId,
						}));
					});

					missingOutputs.forEach((output) => {
						const portIdentifier = createWidgetPortIdentifier(widgetId, 'output', output.name);
						dispatch(removeWidgetPortConnectionsAction({
							portName: portIdentifier,
							recipeId,
							widgetId,
						}));
					});

					/* const combinedPorts = [...missingInputs, ...missingOutputs];
					if (combinedPorts.length) {
						combinedPorts.forEach((port) => {
							// FIXME: This is in fact removing the connection in the recipe, but redux remains unchanged, so once the port is added again
							// if a widget that was previously connected attempts to stablish a new connection, the `connectGatesReducer` will fail because
							// there is a reference of a child already added. Here we should instead, trigger a dispatch to remove the connection from both
							// redux and the recipe. How to replicate:
							// 1. Add a chatgpt widget
							// 2. Connect a text box to its `query` input
							// 3. Enter the service settings and set a default query and save. This would cause this line to be executed and the children be removed.
							// 4. Enter the settings again and disable the default query. This would cause the `query` port to be added once again.
							// 5. Try to reconnect the text box to the `query` port. This would fail silently because the `connectGatesReducer` would find a reference to the previous child.
							// so a new connection is never formed.
							// Test Recipe: https://local.kemu.io:3003/mode/browser/KDcEdZUcIu
							// const { removedChildren, removedParents } = removeWidgetPortConnections(recipeId, DEFAULT_THING_ID, widgetId, port.name);
							// TODO: We should instead have a redux action that does the same as `removeWidgetPortConnections`, detect the children and parents and remove them,
							// from both the state and the recipe.
							// UPDATE: This seems to be working but for some reason, now all connections from chatgpt (all children) are also removed
							dispatch(removeWidgetPortConnectionsAction({
								portName: port.name,
								recipeId,
								widgetId,
							}));
						});
					} */

					currentState.dynamicInputs = currentState.dynamicInputs || {};
					currentState.dynamicOutputs = currentState.dynamicOutputs || {};

					// Remove dynamic ports only when null is specifically set
					if (newInputs === null) {
						currentState.dynamicInputs[DefaultVariantId] = undefined;
					} else if (newInputs) {
						currentState.dynamicInputs[DefaultVariantId] = newInputs;
					}

					if (newOutputs === null) {
						currentState.dynamicOutputs[DefaultVariantId] = undefined;
					} else if (newOutputs) {
						// Not just `else` since newOutputs might not have been provided as well. Only a hard `null` is a signal to remove the outputs
						currentState.dynamicOutputs[DefaultVariantId] = newOutputs;
					}

					return currentState;

					// TODO: Schedule a connections repaint?
				}, true);
			},

			setWidgetDimensions: (width, height) => {
				dispatch(setWidgetDimensions({
					recipeId,
					blockRecipeId: DEFAULT_THING_ID,
					height,
					width,
					sourceGateId: widgetId,
				}));
			},
			getWidgetDimensions: getDimensions,
			utils: {
				browser: {
					getCacheFilePath: (fileName: string) => buildCacheFilePath(fileName, serviceName, serviceVersion),
					cacheFile: handleCacheFile(getCache, serviceName, serviceVersion),
					getCachedFile: handleGetCachedFile(getCache, serviceName, serviceVersion),
					clearServiceCache: handleClearServiceCache(getCache, serviceName, serviceVersion),
					removeCachedFile: handleRemoveCachedFile(getCache, serviceName, serviceVersion),
				},
				showChooseDirectoryDialog: async () => {
					const result = await connector.executeHubFunction<string[]>(KemuHubFunctions.ChooseDirectoryDialog, [
						{
							allowMultiple: false,
						} as ChooseDirectoryDialogArgs
					], {
						// disable timeouts
						timeout: 0,
					});

					let singlePath = '';
					if (result) {
						console.log('Result from choose directory dialog', singlePath);
						singlePath = result[0];
					}

					return singlePath;
				},

				showChooseFileDialog: async () => {
					const result = await connector.executeHubFunction(KemuHubFunctions.ChooseFileDialog, [
						{
							allowMultiple: false,
						} as ChooseDirectoryDialogArgs
					], {
						// disable timeouts
						timeout: 0,
					});

					console.log('Result from choose file dialog', result);
					return result as string[];
				}
			},
			recipeId,
			widgetId,
			variantId: getState().variantId,
			manifest: { ...uiInstanceManifest, color: finalColor },
			callProcessorHandler: async (name, data) => {
				if (serviceSessionId === undefined) { return; }
				const st = getState();
				const r = connector.callProcessorHandler(
					serviceSessionId, {
						currentState: st.customState,
						recipeId,
						widgetId,
						variantId: st.variantId,
						recipeType: RecipeType.Browser,
					},
					name,
					data
				);
				// Hub service offline
				if (r === null) { return; }

				return r;
			},
			setOutputs: async (outputs) => {
				for (const output of outputs) {
					const data: Data = {
						timestamp: Date.now(),
						type: output.type,
						value: output.value,
					};

					await invokeNextGate(
						recipeId,
						DEFAULT_THING_ID,
						DEFAULT_THING_ID,
						DEFAULT_THING_VERSION,
						widgetId,
						output.name,
						data,
						data
					);
				}
			},
			useWidgetState: () => {
				const hubState = getState();
				return {
					state: hubState.customState,
					getState: () => getState().customState,
					setState: (newState, triggerEvents=true) => {
						return setWidgetState((c) => {
							return {
								...c,
								customState: newState,
							};
						}, triggerEvents);
					},
				};
			},
		};

		return context;
	}, [
		setWidgetState, getState, dispatch,
		manifest, recipeId, widgetId, serviceSessionId,
		connector, finalColor, getDimensions, serviceName, serviceVersion,
	]);

	const handleDestroyInstance = useCallback(() => {
		console.log('Unmounting widget');
		if (widgetUIRef.current) {
			// Destroy mounted element
			widgetUIRef.current.destroy();
			// Remove reference to force creating a new instance
			widgetUIRef.current = null;
		}
	}, []);

	const rebuildAndMount = useCallback(() => {
		if (customUITarget) {
			console.log('Rebuilding and mounting widget');
			const contentsBuffer = uiContent as unknown as Uint8Array;
			const decoded = new TextDecoder().decode(contentsBuffer);
			const compileWidgetUI = new Function(decoded);
			try {

				const context: Record<string, unknown> & {WidgetUI?: WidgetUIComponent} = {
					React: React,
					ReactDOM: {
						...ReactDOM,
						createRoot,
					},
					EmotionReact: emotionReact,
					EmotionCache: emotionCache,
				};

				compileWidgetUI.call(context);
				if (context.WidgetUI) {
					if (context.WidgetUI.mountComponent) {
						const instance = context.WidgetUI.mountComponent(customUITarget, {
							// Client is requesting its destruction
							onDestroy: () => {
								console.log('Child component requesting destruction');
								// handleDestroyInstance();
							},
							repaintPorts: () => {
								// ugly workaround for when the widget is first added
								// FIXME: This is re-used every time the widget resizes!
								// setTimeout(repaintPorts, 500);
								repaintPorts();
							},
							globalContext: customWidgetContext,
						});

						widgetUIRef.current = instance;
					}
				}
			} catch (e) {
				console.log(e);
			}
		}
	}, [customUITarget, uiContent, customWidgetContext, repaintPorts]);

	const variantInfo = useMemo(() => {
		const variantId = customWidgetContext?.variantId;
		if (!variantId) { return null; }

		const variant = customWidgetContext.manifest.variants?.find((v) => v.id === variantId);
		if (!variant) { return null; }

		return variant;
	}, [customWidgetContext]);


	// Handles mounting the widget as soon as there is a target and UI content
  useEffect(() => {
		if (customUITarget && uiContent && !widgetUIRef.current && serviceSessionId) {
			console.log('Mounting widget from content detection');
			rebuildAndMount();
		}
	}, [rebuildAndMount, customUITarget, uiContent, serviceSessionId]);


	// Unmounts the widget if the manifest or the UI contents change
	useEffect(() => {
		if (widgetUIRef.current && contentsChecksum && manifestChecksum && serviceSessionId) {
			if (!alreadyMountedRef.current) {
				alreadyMountedRef.current = true;
				return;
			}

			console.log('Checksums change detected, unmounting widget');
			console.log(`Contents checksum: ${contentsChecksum}, Manifest checksum: ${manifestChecksum}`);
			setImmediate(() => {
				handleDestroyInstance();
				rebuildAndMount();
			});
		}
	}, [contentsChecksum, manifestChecksum, handleDestroyInstance, rebuildAndMount, serviceSessionId]);

	const handleBroadcastEvent = useCallback(async (event: DeepReadOnly<BroadcastEvent>) => {
		if (widgetUIRef.current?.handleBroadcastEvent) {
			await widgetUIRef.current.handleBroadcastEvent(event);
		}
	}, []);

	const handleSetOutputsEvent = useCallback(async (outputs: DeepReadOnlyArray<TargetOutput>) => {
		if (widgetUIRef.current?.handleSetOutputsEvent) {
			await widgetUIRef.current.handleSetOutputsEvent(outputs);
		}
	}, []);

	// Register an event listener for broadcast events from the service
	useHubBroadcastEvent(widgetId, serviceName, serviceVersion, handleBroadcastEvent, !eventEmitter);
	useHubSetOutputsEvent(widgetId, serviceName, serviceVersion, handleSetOutputsEvent);

  // IMPORTANT: Re-render the widget on every state change
  if (widgetUIRef.current) {
    widgetUIRef.current.render({
			disabled,
			serviceOnline,
		});
  }


	// Register an event listener for parent events
	useEffect(() => {
		KemuCore.onGateInvoked(handleParentEvent, DEFAULT_THING_ID, widgetId);

		return () => {
			KemuCore.offGateInvoked(handleParentEvent, DEFAULT_THING_ID, widgetId);
			handleDestroyInstance();
		};
	}, [handleParentEvent, widgetId, handleDestroyInstance]);

  return (
		<>
			<div ref={setCustomUITarget} className={classNames(styles.CustomWidgetUI, {
				[styles.Hidden]: hidden,
			})}>
				{/* 
					Custom widgetUI will be rendered here 
				*/}
			</div>

			<div className={classNames(styles.ShortTitle, {
				[styles.Hidden]: hidden,
				[styles.InsideGroup]: !!visibleGroup,
			})}>
				{variantInfo?.name || manifest.shortTitle || manifest.title || manifest.name}
				{disabled && <br/>}
				{disabled && <span className={styles.Disabled}> ({cw('Disabled').toLocaleLowerCase()})</span>}
			</div>
		</>
  );
};

export default HsUIWrapper;
