import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { notification } from 'antd';
import { selectNotification } from '@src/features/interface/interfaceSlice';

const GlobalNotifications = () => {
  const [api, contextHolder] = notification.useNotification();
  const info = useSelector(selectNotification);

  useEffect(() => {
    if (info) {
      api.open({
        type: info.type,
        message: info.title,
        description: info.message,
        placement: 'topRight',
        duration: info.duration,
        key: info.code,
        onClose: () => {
          api.destroy();
        },
      });
    } else {
      api.destroy();
    }

  }, [info, api]);

  return contextHolder;
};

export default GlobalNotifications;
