import { useDispatch } from 'react-redux';
import { useCallback, useMemo } from 'react';
import { NotificationInfo, showGlobalNotification } from '@src/features/interface/interfaceSlice';

const useGlobalNotification = () => {
  const dispatch = useDispatch();

  const showNotification = useCallback((notification: NotificationInfo | null) => {
    dispatch(showGlobalNotification(notification));
  }, [dispatch]);

  const result = useMemo(() => ({ showNotification }), [showNotification]);
  return result;
};

export default useGlobalNotification;
