import React, { memo, useCallback, useContext, useEffect, useRef, useState } from 'react';
import {
  HubServiceProjectConfig,
  ProjectType,
} from '@kemu-io/kemu-core/types';
import Alert from 'antd/lib/alert';
import Radio from 'antd/lib/radio';
import Space from 'antd/lib/space';
import Select from 'antd/lib/select';
import type { RadioChangeEvent } from 'antd/lib';
import { BroadcastEvent, DataTypeStr, EnvironmentInfo, SerializableServiceInfo, ValidPlatformArch } from '@kemu-io/hs-types';
import classNames from 'classnames';
import type { TextAreaRef } from 'antd/lib/input/TextArea';
import { useSelector } from 'react-redux';
import { WidgetLaunchpadContext } from '../../LaunchpadContext';
import styles from './ServiceCreationModal.module.css';
import RoundedModal from '@components/roundedModal/roundedModal';
import useTranslation from '@hooks/useTranslation';
import FormGroup from '@components/form-control/formGroup/formGroup';
import StyledInput from '@components/form-control/styledInput/styledInput';
import KemuSwitch from '@components/form-control/kemuSwitch/KemuSwitch';
import StyledLabel from '@components/form-control/styledLabel/styledLabel';
import ColorSwatch from '@components/colorSwatch/colorSwatch';
import StyledTextArea from '@components/form-control/styledTextArea/styledTextArea';
import useHandleServiceBroadcast from '@hooks/useHandleServiceBroadcast';
import connectionManager, { KemuAppIdIdentifier } from '@src/app/kemuHub/connectionManager';
import useCommonWords from '@hooks/useCommonWords';
import { selectSignedUserProfile } from '@src/app/reducers/user/userSlice';

type Props = {
  visible: boolean;
  onClose: () => void;
}

const ProjectCreatorServiceName = 'kemu.io.projectCreator';
const generateProjectEvent = 'generateProject';

/**
 * Helper function to find the project creator service in the list of active services.
 * @param services a list of active services
 * @returns a service info object if the project creator service is found, otherwise null.
 */
export const findProjectCreatorService = (services: SerializableServiceInfo[]): SerializableServiceInfo | null => {
  const projectCreator  = services.find((service) => service.name === ProjectCreatorServiceName && service.internal);
  return projectCreator || null;
};

const defaultSupportedPlatforms: ValidPlatformArch[] = ['win-x86', 'win-x64', 'osx-x64'];

const getDefaultConfig = (): HubServiceProjectConfig => {
  // Get the last 5 digits of the current timestamp
  const devSessionId = Date.now().toString().slice(-5);

  return {
    type: ProjectType.Service,
    path: '',
    editor: 'vscode',
    installPackages: true,
    language: 'typescript',
    packageManager: 'npm',
    devSessionId: parseInt(devSessionId, 10),
    manifest: {
      name: '',
      description: '',
      color: '#4e3eff', // Kemu's primary
      eventEmitter: false,
      inputs: [{ name: 'input', type: DataTypeStr.Number }],
      outputs: [{ name: 'output', type: DataTypeStr.Number }],
      title: 'My test service',
      shortTitle: 'Test',
      version: '0.0.1',
      widgetUI: false,
      supportedPlatforms: defaultSupportedPlatforms,
    }
  };
};

const defaultColors = [
	'#42a5f5', '#ab47bd', '#626060',
	'#68bb6c', '#eac43e', '#ff7070',
	'#CE69FC', '#FF6822', '#FF9361',
	'#FFB834', '#02AF97', '#777898',
	'#7CE898', '#FF8CA7', '#0291AF',
	'#0094DF', '#84D2F9', '#00B9DF',
	'#A9B8C2', '#A15D1A', '#20282E'
];

const supportedArchitectures: { label: string; value: ValidPlatformArch }[] = [
  { label: 'Windows x64', value: 'win-x64' },
  { label: 'Windows x86', value: 'win-x86' },
  { label: 'Windows ARM', value: 'win-arm' },
  { label: 'Windows ARM64', value: 'win-arm64' },
  { label: 'macOS x64', value: 'osx-x64' },
  { label: 'macOS ARM64', value: 'osx-arm64' },
];

const ServiceCreationModal = (props: Props) => {
  const t = useTranslation('Modal.HubService.CreateService');
  const cw = useCommonWords();
  const { setPreventClose } = useContext(WidgetLaunchpadContext);
  const [config, setConfig] = useState<HubServiceProjectConfig>(getDefaultConfig());
  const { visible, onClose } = props;
  const [logs, setLogs] = useState<string>('');
  const [creationInProgress, setCreationInProgress] = useState<boolean>(false);
  const hubConnector = connectionManager.getConnector();
  const hubOnline = connectionManager.isReady();
  const [processSuccessful, setProcessSuccessful] = useState<boolean>(false);
  const { profile: userProfile } = useSelector(selectSignedUserProfile);
  const logsInputRef = useRef<TextAreaRef>(null);
  const autoScrollSetAt = useRef<number>(0);
  const lastScrollAt = useRef<number>(0);
  // const services = useActiveHubServices();
  const services = hubConnector.getCachedServices().available;
  const serviceCreator  = findProjectCreatorService(services);

  const scrollToBottom = useCallback(() => {
    if (logsInputRef.current?.resizableTextArea?.textArea) {
      const textAreaComponent = logsInputRef.current.resizableTextArea.textArea;
      // Used to detect when the user has scrolled manually
      autoScrollSetAt.current = Date.now();
      textAreaComponent.scrollTop = textAreaComponent.scrollHeight;
    }
  }, []);

  useHandleServiceBroadcast<BroadcastEvent>(serviceCreator?.name, serviceCreator?.version, (event) => {
    if (event.source.serviceName === ProjectCreatorServiceName) {
      const stdout = event.outputs.find(output => output.name === 'stdout');
      if (stdout) {
        setLogs((l) => `${l}${stdout.value}\n`);
        const elapsed = Date.now() - lastScrollAt.current;

        // If the user hasn't scrolled in the last 15 seconds, scroll to the bottom
        if (elapsed > 15000) {
          scrollToBottom();
        }
      }
    }
  }, KemuAppIdIdentifier, !visible);

  const handleClose = useCallback(() => {
    onClose();
    setConfig(getDefaultConfig());
    setCreationInProgress(false);
    setProcessSuccessful(false);
    setLogs('');
  },[onClose]);

  const handleNameChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    let newName = e.target.value.trim().toLocaleLowerCase();
    // Remove invalid characters
    newName = newName.replace(/[^a-zA-Z0-9-_.]/g, '');

    setConfig((c) => ({
      ...c,
      manifest: {
        ...c.manifest,
        name: newName
      }
    }));
  }, []);

  const handleDescriptionChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setConfig((c) => ({
      ...c,
      manifest: {
        ...c.manifest,
        description: e.target.value
      }
    }));
  }, []);

  const handleColorChange = useCallback((color: string) => {
    setConfig((c) => ({
      ...c,
      manifest: {
        ...c.manifest,
        color,
      }
    }));
  }, []);

  const handleLanguageChange = useCallback((e: RadioChangeEvent) => {
    setConfig((c) => ({
      ...c,
      language: e.target.value,
    }));
  }, []);

  const handlePackageManager = useCallback((e: RadioChangeEvent) => {
    setConfig((c) => ({
      ...c,
      packageManager: e.target.value,
    }));
  }, []);

  const handleProjectTypeChange = useCallback((e: RadioChangeEvent) => {
    setConfig((c) => ({
      ...c,
      type: e.target.value,
      manifest: {
        ...c.manifest,
        widgetUI: e.target.value === ProjectType.ServiceUI,
      }
    }));
  }, []);

  const handleEditorChange = useCallback((e: RadioChangeEvent) => {
    setConfig((c) => ({
      ...c,
      editor: e.target.value,
    }));
  }, []);

  const handleInstallDeps = useCallback((checked: boolean) => {
    setConfig((c) => ({
      ...c,
      installPackages: checked,
    }));
  }, []);

  const handleArchitecturesChange = useCallback((values: ValidPlatformArch[]) => {
    setConfig((c) => ({
      ...c,
      manifest: {
        ...c.manifest,
        supportedPlatforms: values,
      }
    }));
  }, []);

  const handleCreate = useCallback(async () => {
    if (!userProfile?.id) { return; }

    setCreationInProgress(true);
    setLogs('Creating service...\n');
    const project: HubServiceProjectConfig = {
      ...config,
      manifest: {
        ...config.manifest,
        author: userProfile.id,
        name: `test.${config.manifest.name}`,
      }
    };

    try {
      if (!serviceCreator) { return; }
      await hubConnector.callProcessorHandler(
        serviceCreator.sessionId,
        {} as EnvironmentInfo,
        generateProjectEvent,
        project,
        {
          // Wait for up to 15 minutes for the project creation to conclude
          timeout: 900000,
        }
      );
      setProcessSuccessful(true);
    } catch (e) {
      console.error('Service creation error:', e);
      setProcessSuccessful(false);
      setLogs((l) => `${l}${e.message || e as string}\n`);
    }

    setCreationInProgress(false);
    scrollToBottom();
  }, [hubConnector, config, scrollToBottom, serviceCreator, userProfile]);

  const handleLogsScroll = useCallback(() => {
    const isAutoScroll = Date.now() - autoScrollSetAt.current < 200;
    if (!isAutoScroll) {
      lastScrollAt.current = Date.now();
    }
  }, []);

  useEffect(() => {
    if (visible) {
      setPreventClose(true);
    }

    return () => {
      setPreventClose(false);
    };
  }, [setPreventClose, visible]);

  return (
    <RoundedModal
      title={t('Title', 'Create Service')}
      visible={visible}
      width={800}
      onCancel={handleClose}
      closeOnMaskClick={false}
      closable={true}
      disableOkButton={
        processSuccessful
        || !hubOnline
        || creationInProgress
        || !config.manifest.name
        || !config.manifest.supportedPlatforms?.length
      }
      disableCancelButton={creationInProgress}
      loadingOkBtn={creationInProgress}
      okBtnLabel={cw('Create')}
      cancelBtnLabel={processSuccessful ? cw('Close') : cw('Cancel')}
      onOk={handleCreate}
    >
      <div className={styles.Content}>
        <FormGroup marginBottomLevel={3}>
          <Space.Compact style={{ width: '100%' }}>
            <div className={styles.InputWithPrefix}>
              <span className={styles.TestPrefix}>test.</span>
                <StyledInput
                  disabled={creationInProgress || processSuccessful}
                  className={classNames(styles.NameInput, styles.CompactInput)}
                  onChange={handleNameChange}
                  value={config.manifest?.name || ''}
                  label={t('Name.Label', 'Name')}
                  placeholder={t('Name.Placeholder', 'companyName.serviceName')}
                />
            </div>
            {/* <input ref={folderSelectRef} type="file" hidden />
            <Button onClick={handleOpenSelectFolder} className={styles.InputBtn} type="primary">...</Button> */}
          </Space.Compact>
        </FormGroup>

        <FormGroup marginBottomLevel={3}>
          <StyledInput
            disabled={creationInProgress || processSuccessful}
            className={styles.CompactInput}
            onChange={handleDescriptionChange}
            value={config.manifest?.description || ''}
            label={t('Description.Label', 'Description')}
            placeholder={t('Description.Placeholder', 'Enter service description')}
          />
        </FormGroup>

        <FormGroup marginBottomLevel={3} column>
          <StyledLabel text={t('ProjectType')} helpText={t('ProjectType.Help')}/>
          <Radio.Group onChange={handleProjectTypeChange} value={config.type}>
            <Radio checked={config.type === ProjectType.Service} value={ProjectType.Service}>{t('ProjectType.ProcessorOnly')}</Radio>
            <Radio checked={config.type === ProjectType.ServiceUI} value={ProjectType.ServiceUI}>{t('ProjectType.ProcessorUI')}</Radio>
          </Radio.Group>
        </FormGroup>

        <FormGroup marginBottomLevel={3} column>
          <StyledLabel text={t('Language')}/>
          <Radio.Group onChange={handleLanguageChange} value={config.language}>
            <Radio defaultChecked={true} value={'typescript'}>Typescript</Radio>
            <Radio disabled value={'javascript'}>Javascript</Radio>
            <Radio disabled value={'python'}>Python</Radio>
          </Radio.Group>
        </FormGroup>

        <FormGroup marginBottomLevel={3} column>
          <StyledLabel text={'Package Manager'} />
          <Radio.Group onChange={handlePackageManager} value={config.packageManager}>
            <Radio defaultChecked={true} value={'npm'}>npm</Radio>
            <Radio value={'pnpm'}>pnpm</Radio>
            {/* <Radio value={'yarn'}>yarn</Radio> */}
          </Radio.Group>
        </FormGroup>

        <FormGroup marginBottomLevel={3} column>
          <StyledLabel text={'Editor'} />
          <Radio.Group onChange={handleEditorChange} value={config.editor}>
            <Radio defaultChecked={true} value={'vscode'}>VSCode</Radio>
            <Radio value={'cursor'}>Cursor</Radio>
            <Radio value={'none'}>None</Radio>
            {/* <Radio disabled value={'sublime'}>Sublime Text</Radio>
            <Radio disabled value={'webstrom'}>Webstrom</Radio> */}
          </Radio.Group>
        </FormGroup>

        <FormGroup marginBottomLevel={3}>
          <div className={styles.ColorContainer}>
            <StyledLabel mb={0} text={'Color'} />
            <ColorSwatch
              className={styles.Swatch}
              width={'350px'}
              popupClassName={styles.SwatchPopup}
              color={config.manifest.color}
              colors={defaultColors}
              onColorChange={handleColorChange}
            />
          </div>

          <FormGroup noMarginBottom column className={styles.SwitchGroup}>
            <StyledLabel mb={0} text={'Auto install dependencies'}/>
            <KemuSwitch
              checked={config.installPackages}
              onChange={handleInstallDeps}
              size='small'
            />
          </FormGroup>
        </FormGroup>

        <FormGroup marginBottomLevel={3} column>
          <StyledLabel text={t('SupportedPlatforms')} helpText={t('SupportedPlatforms.Help')}/>
          <Select
            mode="multiple"
            disabled={creationInProgress || processSuccessful}
            style={{ width: '100%' }}
            placeholder={t('SupportedPlatforms.Placeholder')}
            options={supportedArchitectures}
            value={config.manifest.supportedPlatforms}
            onChange={handleArchitecturesChange}
            defaultValue={defaultSupportedPlatforms}
          />
        </FormGroup>

        <div className={styles.LogsContainer}>
          {!hubOnline ? (
            <div className={styles.HubOfflineMsg}>
              <Alert
                message={t('HubOffline', 'Kemu Hub is currently offline')}
                type="error"
                showIcon
              />
            </div>
          ) : (
            <StyledTextArea
              ref={logsInputRef}
              onScroll={handleLogsScroll}
              className={styles.Logs}
              readOnly
              value={logs}
              label={t('Logs.Label', 'Logs')}
              placeholder={t('Logs.Placeholder', 'Logs will appear here')}
              rows={6}
              preventResize
            />
          )}
        </div>

      </div>
    </RoundedModal>
  );
};

export default memo(ServiceCreationModal);
