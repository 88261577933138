import React, { forwardRef } from 'react';
import Input, { TextAreaProps } from 'antd/lib/input';
import classNames from 'classnames';
import type { TextAreaRef } from 'antd/lib/input/TextArea.js';
import StyledLabel from '../styledLabel/styledLabel';
import styles from './styledTextArea.module.css';

const { TextArea } = Input;

interface Props extends TextAreaProps{
	label?: string | React.ReactNode;
	errorMessage?: string | null;
	/** An error message to show */
	children?: React.ReactNode;
	preventResize?: boolean;
	className?: string;
}

const StyledTextAreaComponent = (props: Props, ref: React.Ref<TextAreaRef>): React.JSX.Element => {
	const { label, errorMessage, children, preventResize, className, ...textAreaProps } = props;
	return (
		<>
			{label && (
				<StyledLabel text={label} />
			)}

			<TextArea
				className={classNames({
					[styles.InvalidInput]: !!errorMessage,
					[styles.NoResize]: preventResize,
				}, className)}
				{...textAreaProps}
				// NOTE: text area instance must be accessed via: `ref.current.resizableTextArea.textArea.value`
				ref={ref}
			/>

			{children ? (
				<p className={styles.ErrorMessage}>{children}</p>
			) : (
				<>
				{ errorMessage &&  <p className={styles.ErrorMessage}>{errorMessage}</p>}
				</>
			)}
		</>
	);
};

const StyledTextArea = forwardRef<TextAreaRef, Props>(StyledTextAreaComponent);
export default StyledTextArea;
