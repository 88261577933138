import React, { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Spin from 'antd/lib/spin';
import message from 'antd/lib/message';
import { LoadingOutlined } from '@ant-design/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { SharedWithUser } from '@kemu-io/kemu-types';
import { toggleLinkShare/*, addSharedUser , removeSharedUser */ } from '../../api/recipe/recipeApi';
import routes from '../../common/routes/index';
import { RecipeCardDetails } from '../RecipeCard/RecipeCard';
import styles from './recipeShare.module.css';
import KemuSwitch from '@components/form-control/kemuSwitch/KemuSwitch';
import StyledButton from '@components/form-control/styledButton/styledButton';
import StyledInput from '@components/form-control/styledInput/styledInput';
import StandardModal from '@components/modal/standardModal';
import FormGroup from '@components/form-control/formGroup/formGroup';

interface Props {
	recipeId: string;
	visible: boolean;
	onDetailsUpdated: (details: RecipeCardDetails) => void;
	onClose?: () => void;
	shareList: SharedWithUser[];
	publicShareId?: string | null;
}


// interface PersonItemProps {
// 	name: string;
// 	email: string;
// 	accessMode: string;
// 	index: number;
// 	onAccessModeSelected: (mode: string, userIndex: number) => void;
// }


// const PersonItem = (props: PersonItemProps): React.JSX.Element => {
// 	const onPermissionSelected = (e: any) => {
// 		props.onAccessModeSelected(e.key, props.index);
// 	};

// 	const menu = (
// 		<Menu onClick={onPermissionSelected}>
// 			<Menu.Item key="Read">
// 				{props.accessMode === 'Read' ? <CheckOutlined /> : <span className={styles.NotSelected} />} <FormattedMessage id="Marketplace.MyRecipes.Card.ShareDialog.Permission.Read" defaultMessage="Read" />
// 			</Menu.Item>
// 			<Menu.Item key="Modify">
// 				{props.accessMode === 'Modify' ? <CheckOutlined /> : <span className={styles.NotSelected} />} <FormattedMessage id="Marketplace.MyRecipes.Card.ShareDialog.Permission.Modify" defaultMessage="Modify" />
// 			</Menu.Item>
// 			<Menu.Item key="removeUser">
// 				{props.accessMode === 'Remove User' ? <CheckOutlined /> : <span className={styles.NotSelected} />} <FormattedMessage id="Marketplace.MyRecipes.Card.ShareDialog.ReadAccess" defaultMessage="Remove User" />
// 			</Menu.Item>
// 		</Menu>
// 	);

// 	return (
// 			<FormGroup row={true} className={styles.PersonItem}>
// 				<span className={`${styles.PersonPicture} c${props.index % 11}`}>{props.name.substring(0, 1)}</span>
// 				<div className={styles.PersonInfo}>
// 					<span className={styles.PersonName}>{props.name}</span>
// 					<span className={styles.PersonEmail}>{props.email}</span>
// 				</div>

// 				<Dropdown trigger={['click']} overlay={menu} className={styles.PermissionDropdown}>
// 					<Button>
// 						{ props.accessMode } <DownOutlined />
// 					</Button>
// 				</Dropdown>
// 			</FormGroup>
// 	);
// };

const RecipeShare = (props: Props): React.JSX.Element => {
	const { /* shareList, */ recipeId, onDetailsUpdated } = props;
	const [loading, setLoading] = useState(false);
	const hasPublicLink = !!props.publicShareId;
	const linkUrl = routes.recipe.getPublicRecipeImportUrl(props.publicShareId || '');
	const intl = useIntl();

	/**
	 * Shows a generic error message.
	 * @param messageId the intl id to use instead of the generic one
	 * @param defaultText default message in case the id is not found in the language file
	 */
	const showError = useCallback((messageId?: string, defaultText?: string) => {
		const msg = intl.formatMessage({
			id: messageId || 'Marketplace.MyRecipes.Card.ShareDialog.RequestErrorGeneric',
			defaultMessage: defaultText || 'There was an error with this request'
		});

		message.error({
			duration: 2,
			content: msg,
		});
	}, [intl]);

	const toggleLinkSharing = async (checked: boolean) => {
		setLoading(true);
		try {
			const publicId = await toggleLinkShare(recipeId, checked);
			onDetailsUpdated({ publicShareId: publicId || null });
		} catch (e) {
			console.log('Error sending link sharing request: ', e);
			showError();
		}

		setLoading(false);
	};


	// const onAccessModeSelected = useCallback(async (mode: string, recordIndex: number) => {
	// 	if(recordIndex < shareList.length){
	// 		let finalList = shareList;
	// 		const userEmail = shareList[recordIndex].email;
	// 		setLoading(true);
	// 		try{
	// 			if(mode === 'removeUser'){
	// 				finalList = await removeSharedUser(recipeId, userEmail);
	// 			}else{
	// 				finalList = await addSharedUser(recipeId, userEmail, mode, '', true);
	// 			}
	// 		}catch(e){
	// 			console.log('Error updating user: ', e);
	// 			showError();
	// 		}

	// 		setLoading(false);
	// 		onDetailsUpdated({
	// 			shareList: finalList
	// 		});
	// 	}

	// }, [shareList, recipeId, onDetailsUpdated, showError]);

	// const onAddShareUser = async() => {
	// 	if(!emailInputRef.current) { return; }
	// 	const email = emailInputRef.current.value.trim();
	// 	try{
	// 		setLoading(true);
	// 		const userList = await addSharedUser(props.recipeId, email, 'Read', '', false);
	// 		props.onDetailsUpdated({
	// 			shareList: userList
	// 		});

	// 		emailInputRef.current.value = '';
	// 	}catch(e){
	// 		console.log('Error adding user: ', e);
	// 		showError();
	// 	}

	// 	setLoading(false);
	// };


	// const onKeyPressed = (event: React.KeyboardEvent<HTMLInputElement>) => {
	// 	if(event.key === 'Enter'){
	// 		onAddShareUser();
	// 	}
	// };

	const onUrlCopied = () => {
		const msg = intl.formatMessage({ id: 'Marketplace.MyRecipes.Card.ShareDialog.CopyMessage', defaultMessage: 'Link copied' });
		message.success({
			duration: 1,
			content: msg,
		});
	};


	return (
		<StandardModal
			dataKemuMeta="recipe-share-dialog"
			onCancel={props.onClose}
			onOk={props.onClose}
			closeOnMaskClick={true}
			okBtnLabel={<FormattedMessage id="Marketplace.MyRecipes.Card.ShareDialog.DoneBtn" defaultMessage="Done" />}
			visible={props.visible}
			title={<FormattedMessage id="Marketplace.MyRecipes.Card.ShareDialog.Title" defaultMessage="Share with others" />}
		>

			<Spin indicator={<LoadingOutlined className={styles.LoadingIcon}/>} spinning={loading}>
				<div className={styles.ViaLinkContainer}>
					<FormGroup row={true}>
						<KemuSwitch
							data-kemu-meta="share-recipe-switch"
							className={styles.ByLinkSwitch}
							checked={hasPublicLink}
							onChange={toggleLinkSharing}
							size="default"
						/>
						<label>
							{hasPublicLink ? (
								<FormattedMessage id="Marketplace.MyRecipes.Card.ShareDialog.LinkSharingOn" defaultMessage="Link sharing is On" />
								) : (
								<FormattedMessage id="Marketplace.MyRecipes.Card.ShareDialog.LinkSharingOff" defaultMessage="Link sharing is Off" />
							) }
						</label>
					</FormGroup>

					{hasPublicLink && (
						<FormGroup className={styles.InputContainer} row={true}>
							<StyledInput readOnly className={styles.Input} label={<FormattedMessage id="Marketplace.MyRecipes.Card.ShareDialog.ViaLinkHeader" defaultMessage="Anyone with this link will be able to access this recipe" />} value={linkUrl} />
							<CopyToClipboard text={linkUrl} onCopy={onUrlCopied}>
								<StyledButton title={<FormattedMessage id="Marketplace.MyRecipes.Card.ShareDialog.CopyBtn" defaultMessage="Copy Link" />} color="light" className={styles.LinkCopyBtn}/>
							</CopyToClipboard>
						</FormGroup>
					)}
				</div>

				{/* Email sharing disabled for now (02/Mar/2021) */}
				{/* <div className={styles.PeopleContainer}>
					<p><FormattedMessage id="Marketplace.MyRecipes.Card.ShareDialog.ViaEmail" defaultMessage="Share this recipe with specific Kemu users" /></p>
					<FormGroup row={true}>
						<StyledInput ref={emailInputRef} onKeyDown={onKeyPressed} title="Share with specific people" i18nPlaceholderId="Marketplace.MyRecipes.Card.ShareDialog.EmailPlaceholder" i18nPlaceholderDefaultMessage="Enter email address" className={styles.UserAddressInput} />
						<IconButton onClick={onAddShareUser} color="light" icon={<PlusOutlined style={{ fontSize: 18 }} />} />
					</FormGroup>

					<div className={styles.PersonList}>
						{(props.shareList || []).map((user, index) => (
							<PersonItem onAccessModeSelected={onAccessModeSelected} key={index} name={user.name} email={user.email} index={index} accessMode={user.accessMode}/>
						))}
					</div>
				</div> */}
			</Spin>
		</StandardModal>
	);
};

export default RecipeShare;
